// React and External Libraries
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { isAuthenticated } from './Auth'; // Auth helper function to check if the user is logged in

// Styles
import './styles/FontAwesome.css';
import './styles/intlTelInput.css';

// Layout Components
import Header from './components/Header';
import LoggedOutHeader from './components/LoggedOutHeader';
import Footer from './components/Footer';

// General Components
import ProtectedRoute from './components/ProtectedRoute';
import { CookiePolicyPage, PrivacyPolicyPage, TermsOfServicePage } from './components/LegalPage';

// Admin pages
import AdminKYC from "./pages/admin/AdminKYC";
import AdminPromotions from "./pages/admin/AdminPromotions";
import AdminReviewPromotions from "./pages/admin/AdminReviewPromotions";
import AdminTemplateEditor from "./pages/admin/AdminTemplateEditor";
import AdminToolsPanel from "./pages/admin/AdminToolsPanel";
import AdminUserEditor from "./pages/admin/AdminUserEditor";
import AdminOrderOverview from "./pages/admin/AdminOrderOverview";
import AdminEditProduct from "./pages/admin/AdminEditProduct";

// General pages
import AccountActivation from "./pages/AccountActivation";
import Assets from "./pages/Assets";
import Checkout from "./pages/Checkout";
import Dashboard from './pages/Dashboard';
import KYC from "./pages/KYC";
import Login from './pages/Login';
import MainPage from "./pages/MainPage";
import Marketplace from "./pages/Marketplace";
import PasswordReset from "./pages/PasswordReset";
import Pricing from "./pages/Pricing";
import ProductOverview from "./pages/ProductOverview";
import Register from "./pages/Register";
import Services from "./pages/Services";
import ShoppingCart from "./pages/ShoppingCart";
import ThankYou from "./pages/ThankYou";
import VerifyEmail from "./pages/VerifyEmail";
import PaymentHistory from "./pages/PaymentHistory";
import LeasingOverview from "./pages/LeasingOverview";
import Budget from "./pages/Budget";
import Support from "./pages/Support";
import Loans from "./pages/Loans";
import FinanceAnalysis from "./pages/FinanceAnalysis";
import ExpenseSummary from "./pages/ExpenseSummary";
import Settings from "./pages/Settings";
import NotificationHistory from "./pages/NotificationHistory";

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        // Check if the user is logged in on mount
        const checkAuthStatus = () => {
            const loggedIn = isAuthenticated(); // Check if token exists or valid
            setIsLoggedIn(loggedIn);
        };

        checkAuthStatus();
    }, []);

    return (
        <Router>
            <div className="flex">
                <div className="flex flex-col w-full">
                    {isLoggedIn ? <Header /> : <LoggedOutHeader />}

                    {/* Main Content */}
                    <main className="flex-grow p-4">
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/register" element={<Register />} />
                            <Route path="/verify/:hash" element={<VerifyEmail />} />
                            <Route path="/pricing" element={<Pricing />} />
                            <Route path="/services" element={<Services />} />
                            <Route path="/reset" element={<PasswordReset />} />

                            <Route path="/cookie-policy" element={<CookiePolicyPage />} />
                            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                            <Route path="/terms-of-service" element={<TermsOfServicePage />} />

                            <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                            <Route path="/assets" element={<ProtectedRoute><Assets /></ProtectedRoute>} />
                            <Route path="/kyc" element={<ProtectedRoute><KYC /></ProtectedRoute>} />
                            <Route path="/cart" element={<ProtectedRoute><ShoppingCart /></ProtectedRoute>} />
                            <Route path="/checkout" element={<ProtectedRoute><Checkout /></ProtectedRoute>} />
                            <Route path="/thankyou" element={<ProtectedRoute><ThankYou /></ProtectedRoute>} />
                            <Route path="/thankyou/:transactionId" element={<ProtectedRoute><ThankYou /></ProtectedRoute>} />
                            <Route path="/marketplace" element={<ProtectedRoute><Marketplace /></ProtectedRoute>} />
                            <Route path="/info/:itemSource/:itemId" element={<ProtectedRoute><ProductOverview /></ProtectedRoute>} />
                            <Route path="/activate" element={<ProtectedRoute><AccountActivation /></ProtectedRoute>} />
                            <Route path="/payment-history" element={<ProtectedRoute><PaymentHistory /></ProtectedRoute>} />
                            <Route path="/leasing-overview" element={<ProtectedRoute><LeasingOverview /></ProtectedRoute>} />
                            <Route path="/budget" element={<ProtectedRoute><Budget /></ProtectedRoute>} />
                            <Route path="/support" element={<ProtectedRoute><Support /></ProtectedRoute>} />
                            <Route path="/loans" element={<ProtectedRoute><Loans /></ProtectedRoute>} />
                            <Route path="/finance-analysis" element={<ProtectedRoute><FinanceAnalysis /></ProtectedRoute>} />
                            <Route path="/expense-summary" element={<ProtectedRoute><ExpenseSummary /></ProtectedRoute>} />
                            <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
                            <Route path="/notifications" element={<ProtectedRoute><NotificationHistory /></ProtectedRoute>} />

                            <Route path="/admin" element={<ProtectedRoute><AdminToolsPanel /></ProtectedRoute>} />
                            <Route path="/admin/kyc" element={<ProtectedRoute><AdminKYC /></ProtectedRoute>} />
                            <Route path="/admin/usereditor" element={<ProtectedRoute><AdminUserEditor /></ProtectedRoute>} />
                            <Route path="/admin/templates" element={<ProtectedRoute><AdminTemplateEditor /></ProtectedRoute>} />
                            <Route path="/admin/promotions" element={<ProtectedRoute><AdminPromotions /></ProtectedRoute>} />
                            <Route path="/admin/reviewpromotions" element={<ProtectedRoute><AdminReviewPromotions /></ProtectedRoute>} />
                            <Route path="/admin/orderhistory" element={<ProtectedRoute><AdminOrderOverview /></ProtectedRoute>} />
                            <Route path="/admin/editproduct" element={<ProtectedRoute><AdminEditProduct /></ProtectedRoute>} />
                            <Route path="/admin/editproduct/new" element={<ProtectedRoute><AdminEditProduct isNewProduct={true} /></ProtectedRoute>} />
                            <Route path="/admin/editproduct/:productId" element={<ProtectedRoute><AdminEditProduct /></ProtectedRoute>} />

                            <Route path="/" element={<MainPage />} />
                            <Route path="*" element={<Navigate to="/" />} />
                        </Routes>
                    </main>

                    {/* Footer */}
                    <Footer />
                </div>
            </div>
        </Router>
    );
}


export default App;
