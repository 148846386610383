import React, { useEffect, useState } from 'react';
import { apiRequest } from '../utils/Api'; // Your existing API request function

const AssetsSection = ({ showKycNotification, limit = null, isFullPage = false }) => {
    const [assets, setAssets] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [offset, setOffset] = useState(0); // Track offset for pagination
    const [hasMore, setHasMore] = useState(true); // To track if more assets are available

    useEffect(() => {
        // When offset changes, load assets (clear assets only for the first load)
        if (offset === 0) {
            // Clear assets on initial load or refresh
            setAssets([]);
        }
        loadAssets();
    }, [offset]);

    // Load user assets from API
    const loadAssets = async () => {
        setIsLoading(true);
        try {
            const response = await apiRequest('User/assets', 'POST', {
                limit: limit || 6, // Default to 6 if no limit is provided
                offset,
            });

            if (response.length === 0) {
                setHasMore(false); // No more assets to load
            } else {
                // Append new assets to the existing ones
                setAssets((prevAssets) => [...prevAssets, ...groupAssets(response)]);
            }
        } catch (error) {
            setError('Failed to load assets.');
        } finally {
            setIsLoading(false);
        }
    };

    // Group assets based on itemId and itemSource, except for courses (itemSource === 1)
    const groupAssets = (assets) => {
        const grouped = assets.reduce((acc, asset) => {
            const { itemId, itemSource } = asset;
            if (itemSource === 1) {
                acc.push({ ...asset, grouped: false });
            } else {
                const existingGroup = acc.find(
                    (group) => group.itemId === itemId && group.itemSource === itemSource && group.grouped
                );
                if (existingGroup) {
                    existingGroup.details.push(asset);
                } else {
                    acc.push({
                        ...asset,
                        grouped: true,
                        details: [asset],
                    });
                }
            }
            return acc;
        }, []);
        return grouped;
    };

    const loadMoreAssets = () => {
        setOffset((prevOffset) => prevOffset + (limit || 6)); // Increase offset by limit
    };

    return (
        <div className={showKycNotification ? 'pointer-events-none opacity-50' : ''}>
            <h2 className="text-2xl font-bold mb-4">Your assets at Bankt</h2>
            {isLoading && offset === 0 ? (
                <p>Loading assets...</p>
            ) : error ? (
                <p className="text-red-500">{error}</p>
            ) : assets.length === 0 ? (
                <h3>No assets available</h3>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {assets.map((asset) => (
                        <div key={asset.id} className="bg-white shadow-md rounded-lg overflow-hidden">
                            <img src={asset.image.replace('/img/', '/images/')} alt={asset.title} className="w-full h-48 object-cover" />
                            <div className="p-4">
                                <h5 className="text-xl font-semibold">{asset.title}</h5>
                                <p className="text-gray-700">{asset.description}</p>
                                {asset.website && (
                                    <a href={asset.website} className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">
                                        Visit Website
                                    </a>
                                )}
                            </div>
                            <div className="p-4">
                                {asset.price > 0 && asset.itemSource !== 1 && asset.grouped ? (
                                    <>
                                        <h5 className="font-bold mb-2">Purchase Details</h5>
                                        <table className="table-auto w-full text-left">
                                            <thead>
                                            <tr>
                                                <th>Quantity</th>
                                                <th>Price per Unit (€)</th>
                                                <th>Total (€)</th>
                                                <th>Date</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {asset.details.map((detail, index) => (
                                                <tr key={index}>
                                                    <td>{detail.quantity}</td>
                                                    <td>{detail.price}</td>
                                                    <td>{(detail.price * detail.quantity).toFixed(2)}</td>
                                                    <td>{new Date(detail.purchasedAt).toLocaleDateString()}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </>
                                ) : asset.price > 0 && asset.itemSource !== 1 ? (
                                    <>
                                        <p className="text-gray-700">
                                            <strong>Purchased At:</strong> {new Date(asset.purchasedAt).toLocaleDateString()}
                                        </p>
                                        <p className="text-gray-700"><strong>Quantity:</strong> {asset.quantity}</p>
                                        <p className="text-gray-700"><strong>Price per Unit:</strong> €{asset.price}</p>
                                        <p className="text-gray-700"><strong>Total Value:</strong> €{(asset.price * asset.quantity).toFixed(2)}</p>
                                    </>
                                ) : asset.itemSource === 1 ? (
                                    <p className="text-gray-700 font-semibold">This course is part of your learning journey at Bankt.</p>
                                ) : (
                                    <p className="text-gray-700 font-semibold">This asset was obtained for free.</p>
                                )}

                                {asset.yield > 0 && (
                                    <p className="text-gray-700 mt-2"><strong>Yield:</strong> {(asset.yield * 100).toFixed(2)}%</p>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {/* Load More Button */}
            {hasMore && !isLoading && (
                <div className="mt-4 text-center">
                    <button
                        onClick={loadMoreAssets}
                        className="bg-customOrange text-white px-6 py-2 rounded-md hover:bg-customDarkBrown"
                    >
                        Load More
                    </button>
                </div>
            )}
        </div>
    );
};

export default AssetsSection;
