import { API_URL, API_TOKEN } from "./config";

// Function to verify the token with the API
export const verifyToken = async (token) => {
    try {
        const response = await fetch(`${API_URL}/Auth/accessTokenVerification`, {
            method: 'POST',
            headers: {
                'X-API-TOKEN': API_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token }),
        });

        const data = await response.json();
        if (data.kycActive > 0) {
            console.log('KYC Active:', data.kycActive);
            localStorage.setItem('kycActive', data.kycActive);
            localStorage.removeItem('kycProcessing');
        } else {
            console.log('KYC Inactive');
            localStorage.setItem('kycActive', '0');
        }
        localStorage.setItem('tokens', data.tokens);
        localStorage.setItem('rank', data.rank);
        if (data.level >= 12) {
            localStorage.setItem('isAdmin', '1');
        } else {
            localStorage.removeItem('isAdmin');
        }
        return data.valid; // Assuming the API returns a boolean 'valid'
    } catch (error) {
        console.error('Error verifying token:', error);
        return false;
    }
};

// Function to check if user has a token stored (basic check, doesn't verify token validity)
export const isAuthenticated = () => {
    const token = localStorage.getItem('token');
    return !!token; // Returns true if a token exists, false otherwise
};

// Function to get the stored token from localStorage
export const getToken = () => {
    return localStorage.getItem('token');
};

// Function to log the user out by clearing the token
export const logout = () => {
    localStorage.removeItem('token');
};
