import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Header = () => {
    const [cartCount, setCartCount] = useState(0);
    const [menuOpen, setMenuOpen] = useState(false); // State to toggle the mobile menu
    const kycActive = localStorage.getItem('kycActive');
    const isAdmin = localStorage.getItem('isAdmin');
    const navigate = useNavigate();

    // Fetch the total items in the cart when the component mounts
    useEffect(() => {
        const handleTotalItems = () => {
            fetch(`${process.env.REACT_APP_API_URL}/ShoppingCart/getTotalItems`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    setCartCount(data); // Directly set the integer returned by the API
                })
                .catch((error) => console.error('Error getting total items:', error));
        };

        handleTotalItems();
    }, []);

    // Handle logout function
    const handleLogout = (event) => {
        fetch(`${process.env.REACT_APP_API_URL}/Auth/logout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
        })
            .then(() => {
                localStorage.clear(); // Clear all stored data
                window.location.assign('/'); // Redirect to the homepage
            })
            .catch((error) => console.error('Error logging out:', error));
    };

    // Toggle the mobile menu
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    // Close the menu when a link is clicked
    const closeMenu = () => {
        setMenuOpen(false);
    };

    return (
        <nav className="bg-gray-800 text-white w-full">
            <div className="flex justify-between items-center px-4 py-3">
                {/* Logo and Branding */}
                <Link to="/dashboard" className="flex items-center space-x-2 w-20">
                    <img src="/images/logo.webp" alt="Mstrpayment Logo" className="w-auto h-6" />
                </Link>

                {/* Hamburger Menu for Mobile */}
                <button
                    onClick={toggleMenu}
                    className="sm:hidden text-gray-400 hover:text-white focus:outline-none"
                >
                    <i className="fas fa-bars text-2xl"></i>
                </button>

                {/* Desktop Navigation */}
                <div className="hidden sm:flex space-x-4">
                    <Link to="/dashboard" className="hover:text-gray-300">Dashboard</Link>
                    <Link to="/support" className="hover:text-gray-300">Support</Link>
                    <Link to="/settings" className="hover:text-gray-300">Settings</Link>
                    {isAdmin && <Link to="/admin" className="hover:text-gray-300">Admin</Link>}
                </div>

                {/* Cart and Logout */}
                <div className="hidden sm:flex items-center space-x-4">
                    <button onClick={handleLogout} className="hover:underline">
                        <i className="fas fa-sign-out-alt"></i>
                    </button>
                </div>
            </div>

            {/* Mobile Menu */}
            {menuOpen && (
                <div className="sm:hidden bg-gray-800 text-white px-4 py-6">
                    <div className="grid grid-cols-3 gap-4">
                        <div
                            className="flex flex-col items-center bg-gray-700 p-4 rounded-lg cursor-pointer"
                            onClick={() => { navigate('/dashboard'); closeMenu(); }}
                        >
                            <i className="fas fa-home text-xl text-blue-500 mb-2"></i>
                            <p className="text-sm text-center">Dashboard</p>
                        </div>
                        <div
                            className="flex flex-col items-center bg-gray-700 p-4 rounded-lg cursor-pointer"
                            onClick={() => { navigate('/support'); closeMenu(); }}
                        >
                            <i className="fas fa-life-ring text-xl text-red-500 mb-2"></i>
                            <p className="text-sm text-center">Support</p>
                        </div>
                        {isAdmin && (
                            <div
                                className="flex flex-col items-center bg-gray-700 p-4 rounded-lg cursor-pointer"
                                onClick={() => { navigate('/admin'); closeMenu(); }}
                            >
                                <i className="fas fa-tools text-xl text-yellow-500 mb-2"></i>
                                <p className="text-sm text-center">Admin</p>
                            </div>
                        )}
                        <div
                            className="flex flex-col items-center bg-gray-700 p-4 rounded-lg cursor-pointer"
                            onClick={() => { handleLogout(); closeMenu(); }}
                        >
                            <i className="fas fa-sign-out-alt text-xl text-gray-400 mb-2"></i>
                            <p className="text-sm text-center">Logout</p>
                        </div>
                    </div>
                </div>
            )}
        </nav>
    );
};

export default Header;
