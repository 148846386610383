import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { apiRequest } from '../../utils/Api'; // Adjust the path to your API utility

const AdminToolsPanel = () => {
    const [adminCommands, setAdminCommands] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // Fetch the admin commands
    useEffect(() => {
        const fetchAdminCommands = async () => {
            try {
                const response = await apiRequest('Admin/adminCommands', 'POST', {});

                // Check if unauthorized
                if (response.status === 'error' && response.message === 'Unauthorized') {
                    alert('Unauthorized access. You need to be an admin to access this page.');
                    navigate('/login'); // Redirect to login or an appropriate page
                } else {
                    // Store admin commands
                    setAdminCommands(response);
                }
            } catch (error) {
                setError('Error fetching admin commands');
            } finally {
                setLoading(false);
            }
        };

        fetchAdminCommands();
    }, [navigate]);

    if (loading) return <div className="text-center py-4">Loading...</div>;
    if (error) return <div className="text-center py-4 text-red-500">{error}</div>;

    return (
        <div className="container mx-auto py-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6" id="adminList">
                {adminCommands && Object.keys(adminCommands).length === 0 ? (
                    <h3 className="text-center col-span-full">No commands available</h3>
                ) : (
                    Object.entries(adminCommands).map(([key, command], index) => (
                        <div key={index} className="bg-white shadow-md rounded-lg overflow-hidden">
                            <img
                                src={`/images/${command.image}`}
                                alt={command.title}
                                className="w-full h-40 object-cover"
                            />
                            <div className="p-4">
                                <h5 className="text-lg font-bold">{command.title}</h5>
                                <p className="text-gray-200">{command.description}</p>
                                <Link
                                    to={`/admin/${key}`} // Assuming `key` is the URL path
                                    className="mt-4 block bg-customOrange text-white py-2 rounded-md text-center hover:bg-customDarkBrown"
                                >
                                    Open
                                </Link>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default AdminToolsPanel;
