import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { API_URL, API_TOKEN, SITE_NAME } from '../config';

const LoggedOutHeader = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [cookieBannerVisible, setCookieBannerVisible] = useState(false); // State to manage cookie banner visibility
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        // Check if the cookie consent has been accepted
        const cookieAccepted = localStorage.getItem('cookieAccepted');
        if (!cookieAccepted) {
            setCookieBannerVisible(true);
        }
    }, []);

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const toggleMenu = () => setMenuOpen(!menuOpen);

    const handleCookieAccept = () => {
        localStorage.setItem('cookieAccepted', 'true'); // Store the consent
        setCookieBannerVisible(false); // Hide the banner
    };

    const handleLogin = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch(`${API_URL}/Auth/login`, {
                method: 'POST',
                headers: {
                    'X-API-TOKEN': API_TOKEN,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();

            if (data.valid) {
                localStorage.setItem('token', data.token);
                localStorage.setItem('userId', data.userId);
                localStorage.setItem('kycActive', data.kycActive);
                localStorage.setItem('displayName', data.displayName);
                window.location.assign('/dashboard');
            } else {
                setError(data.message || 'Login failed.');
            }
        } catch (error) {
            console.error('Login error:', error);
            setError('An error occurred during login. Please try again.');
        }
    };

    return (
        <>
            {/* Cookie Banner */}
            {cookieBannerVisible && (
                <div className="fixed bottom-0 left-0 w-full bg-gray-800 text-white p-4 z-50 flex justify-between items-center">
                    <p className="text-sm">
                        This site uses cookies to improve your experience. By using this site, you agree to our <Link to="/cookie-policy" className="underline">cookie policy</Link>.
                    </p>
                    <button
                        onClick={handleCookieAccept}
                        className="ml-4 px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600"
                    >
                        Okay
                    </button>
                </div>
            )}

            <nav className="bg-gray-800 text-customGray">
                <div className="container mx-auto px-4 py-3 flex justify-between items-center">
                    <Link to="/" className="text-xl font-semibold">
                        <img src="/images/logo.webp" alt="Logo" className="w-auto h-6" />
                    </Link>

                    {/* Hamburger Menu for Mobile */}
                    <button
                        onClick={toggleMenu}
                        className="sm:hidden text-gray-400 hover:text-white focus:outline-none"
                    >
                        <i className="fas fa-bars text-2xl"></i>
                    </button>

                    {/* Desktop Menu */}
                    <div className="hidden sm:flex space-x-6">
                        <Link
                            to="/services"
                            className={`hover:text-lightRed pb-1 ${
                                location.pathname === '/services' ? 'border-b-2 border-customOrange' : ''
                            }`}
                        >
                            Services
                        </Link>
                        <Link
                            to="/pricing"
                            className={`hover:text-lightRed pb-1 ${
                                location.pathname === '/pricing' ? 'border-b-2 border-customOrange' : ''
                            }`}
                        >
                            Pricing
                        </Link>
                        <Link
                            to="/register"
                            className={`hover:text-lightRed pb-1 ${
                                location.pathname === '/register' ? 'border-b-2 border-customOrange' : ''
                            }`}
                        >
                            Signup
                        </Link>
                    </div>

                    {/* Login Dropdown */}
                    <div className="relative hidden sm:block">
                        <button onClick={toggleDropdown} className="hover:text-lightRed flex items-center">
                            <i className="fas fa-sign-in-alt"></i>
                            <span className="ml-2">Login</span>
                        </button>

                        {dropdownOpen && (
                            <div className="absolute right-0 mt-2 w-64 bg-gray-800 text-gray-100 rounded-md shadow-lg p-4 z-20">
                                <form onSubmit={handleLogin} className="space-y-4">
                                    <div>
                                        <label htmlFor="email" className="block text-sm font-medium text-gray-300">
                                            Email address
                                        </label>
                                        <input
                                            type="email"
                                            id="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder="Email"
                                            className="block w-full px-3 py-2 mt-1 border border-gray-600 rounded-md shadow-sm bg-gray-700 text-gray-200 focus:ring-customOrange focus:border-customOrange sm:text-sm"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="password" className="block text-sm font-medium text-gray-300">
                                            Password
                                        </label>
                                        <input
                                            type="password"
                                            id="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            placeholder="Password"
                                            className="block w-full px-3 py-2 mt-1 border border-gray-600 rounded-md shadow-sm bg-gray-700 text-gray-200 focus:ring-customOrange focus:border-customOrange sm:text-sm"
                                            required
                                        />
                                        <div className="text-right text-sm mt-2">
                                            <Link to="/reset" className="text-customOrange hover:text-customDarkBrown">
                                                Forgot password?
                                            </Link>
                                        </div>
                                    </div>
                                    {error && <p className="text-red-500">{error}</p>}
                                    <button
                                        type="submit"
                                        className="w-full py-2 px-4 rounded-md bg-customOrange hover:bg-customDarkBrown text-gray-800 border border-customDarkBrown"
                                    >
                                        Sign in
                                    </button>
                                </form>
                                <div className="text-center text-sm mt-4">
                                    New here? <Link to="/signup" className="text-customOrange hover:text-customDarkBrown">Join Us</Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {/* Mobile Menu */}
                {menuOpen && (
                    <div className="sm:hidden bg-gray-800 text-customGray space-y-4 p-4">
                        <Link to="/services" className="block hover:text-lightRed">Services</Link>
                        <Link to="/pricing" className="block hover:text-lightRed">Pricing</Link>
                        <Link to="/register" className="block hover:text-lightRed">Signup</Link>
                        <button
                            onClick={toggleDropdown}
                            className="block hover:text-lightRed w-full text-left"
                        >
                            Login
                        </button>
                        {dropdownOpen && (
                            <div className="bg-gray-800 text-gray-100 rounded-md shadow-lg p-4">
                                <form onSubmit={handleLogin} className="space-y-4">
                                    <div>
                                        <label htmlFor="email-mobile" className="block text-sm font-medium text-gray-300">
                                            Email address
                                        </label>
                                        <input
                                            type="email"
                                            id="email-mobile"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder="Email"
                                            className="block w-full px-3 py-2 mt-1 border border-gray-600 rounded-md shadow-sm bg-gray-700 text-gray-200 focus:ring-customOrange focus:border-customOrange sm:text-sm"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="password-mobile" className="block text-sm font-medium text-gray-300">
                                            Password
                                        </label>
                                        <input
                                            type="password"
                                            id="password-mobile"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            placeholder="Password"
                                            className="block w-full px-3 py-2 mt-1 border border-gray-600 rounded-md shadow-sm bg-gray-700 text-gray-200 focus:ring-customOrange focus:border-customOrange sm:text-sm"
                                            required
                                        />
                                        <div className="text-right text-sm mt-2">
                                            <Link to="/reset" className="text-customOrange hover:text-customDarkBrown">
                                                Forgot password?
                                            </Link>
                                        </div>
                                    </div>
                                    {error && <p className="text-red-500">{error}</p>}
                                    <button
                                        type="submit"
                                        className="w-full py-2 px-4 rounded-md bg-customOrange hover:bg-customDarkBrown text-gray-800 border border-customDarkBrown"
                                    >
                                        Sign in
                                    </button>
                                </form>
                            </div>
                        )}
                    </div>
                )}
            </nav>
        </>
    );
};

export default LoggedOutHeader;
