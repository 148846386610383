import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import {Link} from "react-router-dom";

const ExpenseSummary = () => {
    const chartRef = useRef(null);
    const chartInstance = useRef(null); // To keep track of the Chart.js instance

    // Placeholder data
    const expenseData = [
        { category: 'Groceries', amount: 350 },
        { category: 'Transportation', amount: 120 },
        { category: 'Utilities', amount: 200 },
        { category: 'Entertainment', amount: 150 },
        { category: 'Dining Out', amount: 100 },
        { category: 'Miscellaneous', amount: 80 },
    ];

    const totalExpenses = expenseData.reduce((sum, item) => sum + item.amount, 0);

    useEffect(() => {
        // Initialize or update chart
        const createChart = () => {
            const ctx = chartRef.current.getContext('2d');

            // Destroy existing chart if it exists
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }

            // Create new chart
            chartInstance.current = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: expenseData.map((item) => item.category),
                    datasets: [
                        {
                            label: 'Expenses (€)',
                            data: expenseData.map((item) => item.amount),
                            backgroundColor: 'rgba(75, 192, 192, 0.5)',
                            borderColor: 'rgba(75, 192, 192, 1)',
                            borderWidth: 1,
                        },
                    ],
                },
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                    scales: {
                        x: {
                            title: {
                                display: true,
                                text: 'Category',
                            },
                        },
                        y: {
                            title: {
                                display: true,
                                text: 'Amount (€)',
                            },
                            beginAtZero: true,
                        },
                    },
                },
            });
        };

        createChart();

        // Cleanup on unmount
        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, [expenseData]);

    return (
        <div className="bg-gray-900 text-white min-h-screen px-6 py-8">
            <h1 className="text-3xl font-bold mb-6">Expense Summary</h1>
            <Link to="/dashboard" className="text-customOrange hover:text-customDarkBrown underline mb-6 block">
                Back to Dashboard
            </Link>
            {/* Overview Section */}
            <div className="bg-gray-800 p-4 rounded-lg mb-6">
                <h2 className="text-lg font-semibold mb-2">Overview</h2>
                <p>Total Monthly Expenses: <span className="font-bold">€{totalExpenses}</span></p>
                <p>
                    Largest Expense:{" "}
                    <span className="font-bold">
                        {
                            expenseData.reduce((prev, current) =>
                                prev.amount > current.amount ? prev : current
                            ).category
                        }
                    </span>{" "}
                    (€{Math.max(...expenseData.map((item) => item.amount))})
                </p>
            </div>

            {/* Expense Chart Section */}
            <div className="bg-gray-800 p-4 rounded-lg mb-6">
                <h2 className="text-lg font-semibold mb-4">Expense Breakdown</h2>
                <canvas ref={chartRef}></canvas>
            </div>

            {/* Suggestions Section */}
            <div className="bg-gray-800 p-4 rounded-lg">
                <h2 className="text-lg font-semibold mb-4">Suggestions</h2>
                <ul className="list-disc pl-5">
                    <li>
                        <span className="font-bold">Groceries:</span> Consider meal planning to reduce waste.
                    </li>
                    <li>
                        <span className="font-bold">Transportation:</span> Look into public transport or carpooling.
                    </li>
                    <li>
                        <span className="font-bold">Entertainment:</span> Explore free or low-cost activities.
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default ExpenseSummary;
