import React from "react";
import { Link } from "react-router-dom";

// Helper function to generate weekly payment data
const generateWeeklyPayments = (startDate, weeks) => {
    const payments = [];
    let currentDate = new Date(startDate);

    for (let i = 1; i <= weeks; i++) {
        payments.push({
            id: i,
            date: currentDate.toISOString().split("T")[0], // Format as YYYY-MM-DD
            amount: "€2.50",
            status: i <= 3 ? "Paid" : "Upcoming", // Mark first 3 payments as "Paid", the rest as "Upcoming"
        });
        currentDate.setDate(currentDate.getDate() + 7); // Move to the next week
    }

    return payments;
};

const LeasingOverview = () => {
    // Mock data for the leasing overview
    const leasingDetails = {
        totalWeeks: 32,
        paidWeeks: 3,
        weeklyCost: 2.5,
        startDate: "2024-11-25",
    };

    // Generate weekly payment history
    const leasingHistory = generateWeeklyPayments(leasingDetails.startDate, leasingDetails.totalWeeks);

    // Calculate progress percentage
    const progressPercentage = (leasingDetails.paidWeeks / leasingDetails.totalWeeks) * 100;

    return (
        <div className="bg-gray-900 text-white min-h-screen p-6">
            <h1 className="text-3xl font-bold mb-4">Leasing Overview</h1>
            <Link to="/dashboard" className="text-customOrange hover:text-customDarkBrown underline mb-6 block">
                Back to Dashboard
            </Link>

            {/* Leasing Details */}
            <div className="bg-gray-800 p-4 rounded-lg mb-6 flex flex-wrap items-center">
                <div className="flex-grow">
                    <div className="flex items-center mb-4">
                        <div className="text-white p-2">
                            <h2 className="text-lg font-semibold">Leasing information</h2>
                            <p>Lease Duration: {leasingDetails.totalWeeks} weeks</p>
                            <p>Paid: {leasingDetails.paidWeeks} out of {leasingDetails.totalWeeks} weeks</p>
                            <p>Weekly Cost: €{leasingDetails.weeklyCost.toFixed(2)}</p>
                            <p className="text-gray-400 mt-2">
                                Next Payment: {leasingHistory.find(p => p.status === "Upcoming")?.date || "Completed"}
                            </p>
                        </div>
                        <div className="flex items-center">
                            <img
                                src="/images/smart128.webp"
                                alt="Leased Smartphone"
                                className="w-24 h-24 object-cover rounded-lg ml-4"
                            />
                        </div>
                    </div>

                    {/* Progress Bar */}
                    <div className="mt-4 p-2">
                        <div className="w-full bg-gray-700 rounded-full h-4">
                            <div
                                className="bg-green-500 h-4 rounded-full"
                                style={{width: `${progressPercentage}%`}}
                            ></div>
                        </div>
                        <p className="text-sm mt-2 text-gray-400">
                            {progressPercentage.toFixed(0)}% of payments completed
                        </p>
                    </div>
                </div>
            </div>

            {/* Payment History */}
            <div className="bg-gray-800 p-4 rounded-lg">
                <h2 className="text-lg font-semibold mb-4">Payment History</h2>
                {/* Column Headers */}
                <div className="flex justify-between px-3 py-2 border-b border-gray-700 text-gray-400 font-medium">
                    <span className="w-1/3">Date</span>
                    <span className="w-1/3 text-center">Amount</span>
                    <span className="w-1/3 text-right">Status</span>
                </div>
                {/* Payment History Rows */}
                {leasingHistory.map((entry) => (
                    <div key={entry.id}
                         className="flex justify-between items-center px-3 py-2 border-b border-gray-700">
                        <p className="w-1/3 text-gray-400 text-sm">{entry.date}</p>
                        <p className="w-1/3 text-center font-bold text-lg">{entry.amount}</p>
                        <p
                            className={`w-1/3 text-right text-sm ${
                                entry.status === "Paid" ? "text-green-400" : "text-yellow-400"
                            }`}
                        >
                            {entry.status}
                        </p>
                    </div>
                ))}
            </div>

        </div>
    );
};

export default LeasingOverview;
