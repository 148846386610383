import React from 'react';
import { Link } from 'react-router-dom';

const Pricing = () => {
    return (
        <div className="container mx-auto py-10">
            <div className="text-center mb-12">
                <h1 className="text-4xl font-bold text-gray-100">Our Pricing Plans</h1>
                <p className="text-lg text-gray-200 mt-4 max-w-3xl mx-auto">
                    Choose a plan that works best for your financial goals. Whether you're just starting, actively trading, or managing investments for your business, we have the right solution for you.
                </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">

                {/* Plus Plan */}
                <div className="bg-gray-800 shadow-lg rounded-lg text-center">
                    <div className="bg-customOrange text-white p-6 rounded-t-lg">
                        <h5 className="text-2xl font-bold">Plus</h5>
                    </div>
                    <div className="p-6">
                        <h6 className="text-gray-300 mb-4">Get started with essential features</h6>
                        <ul className="list-none space-y-2 text-left text-gray-400 text-sm">
                            <li>Access to all available stocks and bonds</li>
                            <li>Basic portfolio management tools</li>
                            <li>1% trading fee on all transactions</li>
                            <li>Real-time price tracking</li>
                        </ul>
                    </div>
                    <div className="p-6">
                        <Link to="/register" className="btn bg-customOrange text-white py-2 px-4 rounded-md hover:bg-customDarkBrown">
                            Join Plus
                        </Link>
                    </div>
                </div>

                {/* Pro Plan */}
                <div className="bg-gray-800 shadow-lg rounded-lg text-center">
                    <div className="bg-customOrange text-white p-6 rounded-t-lg">
                        <h5 className="text-2xl font-bold">Pro</h5>
                    </div>
                    <div className="p-6">
                        <h6 className="text-gray-300 mb-4">For active traders and investors</h6>
                        <ul className="list-none space-y-2 text-left text-gray-400 text-sm">
                            <li>All features from Plus</li>
                            <li>Advanced portfolio management</li>
                            <li>0.75% trading fee on all transactions</li>
                            <li>Priority access to IPOs and bond offers</li>
                            <li>Detailed investment analytics and reports</li>
                        </ul>
                    </div>
                    <div className="p-6">
                        <Link to="/register" className="btn bg-customOrange text-white py-2 px-4 rounded-md hover:bg-customDarkBrown">
                            Join Pro
                        </Link>
                    </div>
                </div>

                {/* Enterprise Plan */}
                <div className="bg-gray-800 shadow-lg rounded-lg text-center">
                    <div className="bg-customOrange text-white p-6 rounded-t-lg">
                        <h5 className="text-2xl font-bold">Enterprise</h5>
                    </div>
                    <div className="p-6">
                        <h6 className="text-gray-300 mb-4">For businesses and large-scale investors</h6>
                        <ul className="list-none space-y-2 text-left text-gray-400 text-sm">
                            <li>All features from Pro</li>
                            <li>Dedicated account manager</li>
                            <li>Customizable trading fees</li>
                            <li>API access for automated trading</li>
                            <li>Exclusive access to private placements and bond markets</li>
                            <li>Investment strategy consultations</li>
                        </ul>
                    </div>
                    <div className="p-6">
                        <Link to="/register" className="btn bg-customOrange text-white py-2 px-4 rounded-md hover:bg-customDarkBrown">
                            Join Enterprise
                        </Link>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Pricing;
