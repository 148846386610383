import React from 'react';
import { Link } from 'react-router-dom';
import {SITE_NAME} from "../config"; // Assuming you are using react-router for navigation

const Footer = () => {
    return (
        <footer className="p-4 bg-gray-700 text-white text-center">
            {/* Social Media Section */}
            <div className="p-6 rounded-lg mb-6">
                <div className="flex items-center justify-center space-x-4">
                    <a href="https://facebook.com" target="_blank" rel="noreferrer">
                        <i className="fab fa-facebook text-2xl text-gray-400 hover:text-blue-500"></i>
                    </a>
                    <a href="https://x.com" target="_blank" rel="noreferrer">
                        <i className="fab fa-twitter text-2xl text-gray-400 hover:text-blue-400"></i>
                    </a>
                    <a href="https://instagram.com" target="_blank" rel="noreferrer">
                        <i className="fab fa-instagram text-2xl text-gray-400 hover:text-pink-500"></i>
                    </a>
                    <a href="https://linkedin.com" target="_blank" rel="noreferrer">
                        <i className="fab fa-linkedin text-2xl text-gray-400 hover:text-blue-500"></i>
                    </a>
                </div>
            </div>

            <p>&copy; 2024 {SITE_NAME}. All rights reserved.</p>
            <div className="flex justify-center space-x-4 mt-2">
                <Link to="/cookie-policy" className="hover:underline">
                    Cookie Policy
                </Link>
                <Link to="/privacy-policy" className="hover:underline">
                    Privacy Policy
                </Link>
                <Link to="/terms-of-service" className="hover:underline">
                    Terms of Service
                </Link>
            </div>
        </footer>
    );
};

export default Footer;
