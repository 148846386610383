import React, { useState, useEffect } from 'react';
import { apiRequest } from '../../utils/Api';

const AdminReviewPromotions = () => {
    const [allUserPromotions, setAllUserPromotions] = useState([]);
    const [userPromotions, setUserPromotions] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [tokens, setTokens] = useState(0);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isImageLoading, setIsImageLoading] = useState(false); // New state for image loading spinner

    // Fetch all promotions associated with all users
    const fetchAllUserPromotions = async () => {
        setIsLoading(true);
        try {
            const data = await apiRequest('Admin/listAllPromotionsByUsers', 'POST');
            setAllUserPromotions(data);
            setError(null);
        } catch (err) {
            setError('Failed to load all user promotions.');
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    // Fetch promotions for a selected user based on userId
    const fetchUserPromotionsById = async (userId) => {
        setIsLoading(true);
        try {
            const data = await apiRequest('Admin/listPromotionsById', 'POST', { id: userId });
            setUserPromotions(data);
            setSelectedUserId(userId);

            // Calculate initial token amount based on quantities
            const totalTokens = data.reduce((acc, promo) => acc + (parseInt(promo.quantity) || 0), 0);
            setTokens(totalTokens);

            setError(null);
        } catch (err) {
            setError('Failed to load user promotions.');
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    // Fetch full image for the modal
    const fetchFullImage = async (fileId) => {
        setIsImageLoading(true); // Start spinner
        try {
            const data = await apiRequest('Admin/getFullImage', 'POST', { id: fileId });
            if (data.thumb) {
                setSelectedImage(data.thumb);
                setIsModalOpen(true);
            } else {
                alert('Failed to load image.');
            }
        } catch (error) {
            alert('Error loading full image.');
            console.error(error);
        } finally {
            setIsImageLoading(false); // Stop spinner
        }
    };

    // Approve a promotion
    const approvePromotion = async (promotionId, userId) => {
        if (!window.confirm('Are you sure you want to approve this promotion?')) return;

        try {
            const response = await apiRequest('Admin/approvePromotion', 'POST', {
                userId,
                promotionId,
                tokens, // Total token award based on quantity
            });
            if (response.status === 'success') {
                alert(response.message);
                fetchAllUserPromotions(); // Refresh list after approval
                setUserPromotions([]); // Clear selected user's promotions
                setSelectedUserId(null); // Clear selected user
            } else {
                alert(response.message);
            }
        } catch (error) {
            alert('Error approving promotion.');
            console.error(error);
        }
    };

    // Decline a promotion
    const declinePromotion = async (promotionId, userId) => {
        if (!window.confirm('Are you sure you want to decline this promotion?')) return;

        try {
            const response = await apiRequest('Admin/declinePromotion', 'POST', {
                userId,
                promotionId,
            });
            if (response.status === 'success') {
                alert(response.message);
                fetchAllUserPromotions(); // Refresh list after decline
                setUserPromotions([]); // Clear selected user's promotions
                setSelectedUserId(null); // Clear selected user
            } else {
                alert(response.message);
            }
        } catch (error) {
            alert('Error declining promotion.');
            console.error(error);
        }
    };

    // Load all promotions on component mount
    useEffect(() => {
        fetchAllUserPromotions();
    }, []);

    // Open modal with selected image
    const openModal = (fileId) => {
        fetchFullImage(fileId); // Fetch full image before opening modal
    };

    // Close the modal
    const closeModal = () => {
        setSelectedImage(null);
        setIsModalOpen(false);
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold mb-6">Review User Promotions</h1>

            {/* User Promotions Details (when selected) */}
            {selectedUserId && userPromotions.length > 0 ? (
                <>
                    <h2 className="text-2xl font-bold mb-4">User Promotions</h2>
                    <div className="mb-6">
                        <label className="block text-gray-700">Tokens to Award (Total Based on Quantity)</label>
                        <input
                            type="number"
                            value={tokens}
                            className="w-full border p-2 rounded-md"
                            readOnly
                        />
                    </div>

                    {/* Display user's promotions with read-only quantity */}
                    <table className="table-auto w-full border-collapse border mb-8">
                        <thead>
                        <tr className="bg-gray-100">
                            <th className="border p-2">Promotion</th>
                            <th className="border p-2">Description</th>
                            <th className="border p-2">Proof Image</th>
                            <th className="border p-2">Quantity</th>
                            <th className="border p-2">Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {userPromotions.map((promo) => (
                            <tr key={promo.id}>
                                <td className="border p-2">{promo.promotion}</td>
                                <td className="border p-2">{promo.description}</td>
                                <td className="border p-2 relative">
                                    {isImageLoading ? (
                                        <div className="flex justify-center items-center w-16 h-16">
                                            <div className="loader"></div>
                                        </div>
                                    ) : promo.fileId ? (
                                        <img
                                            src={promo.fileId.thumb}
                                            alt="Proof"
                                            className="w-16 h-16 object-cover cursor-pointer"
                                            onClick={() => openModal(promo.fileId.id)} // Open modal on click
                                        />
                                    ) : (
                                        <span>No Proof</span>
                                    )}
                                </td>
                                <td className="border p-2">{promo.quantity || '0'}</td>
                                <td className="border p-2">
                                    {promo.userStatus === 0 && <span className="text-yellow-500">Pending</span>}
                                    {promo.userStatus === 1 && <span className="text-green-500">Approved</span>}
                                    {promo.userStatus === 2 && <span className="text-red-500">Declined</span>}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                    {/* Approve/Deny Buttons */}
                    {userPromotions.some((promo) => promo.userStatus === 0) && (
                        <div className="mb-8">
                            <button
                                onClick={() => approvePromotion(userPromotions[0].id, selectedUserId)}
                                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 mr-2"
                            >
                                Approve
                            </button>
                            <button
                                onClick={() => declinePromotion(userPromotions[0].id, selectedUserId)}
                                className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                            >
                                Decline
                            </button>
                        </div>
                    )}
                </>
            ) : (
                <p>No promotions selected for this user.</p>
            )}

            {/* All User Promotions List */}
            <h2 className="text-2xl font-bold mb-4">All User Promotions</h2>
            {isLoading ? (
                <p>Loading promotions...</p>
            ) : error ? (
                <p className="text-red-500">{error}</p>
            ) : (
                <table className="table-auto w-full border-collapse border">
                    <thead>
                    <tr className="bg-gray-100">
                        <th className="border p-2">Email</th>
                        <th className="border p-2">Promotion</th>
                        <th className="border p-2">Logo</th>
                        <th className="border p-2">Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {allUserPromotions.map((promo) => (
                        <tr
                            key={promo.id}
                            className="cursor-pointer hover:bg-gray-200"
                            onClick={() => fetchUserPromotionsById(promo.id)}
                        >
                            <td className="border p-2">{promo.email}</td>
                            <td className="border p-2">{promo.promotion}</td>
                            <td className="border p-2">
                                <img src={promo.logo} alt="Logo" className="w-16 h-16 object-cover" />
                            </td>
                            <td className="border p-2">
                                {promo.userStatus === 0 && <span className="text-yellow-500">Pending</span>}
                                {promo.userStatus === 1 && <span className="text-green-500">Approved</span>}
                                {promo.userStatus === 2 && <span className="text-red-500">Declined</span>}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            )}

            {/* Modal for larger image view */}
            {isModalOpen && selectedImage && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center"
                    onClick={closeModal}
                >
                    <div className="relative">
                        <img src={selectedImage} alt="Larger Proof" className="w-auto h-auto max-w-3xl max-h-screen" />
                        <button
                            onClick={closeModal}
                            className="absolute top-2 right-2 text-white bg-red-600 p-2 rounded-full"
                        >
                            &times;
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminReviewPromotions;
