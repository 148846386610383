// pages/admin/AdminEditProduct.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import for navigation
import { apiRequest } from '../../utils/Api';
import ItemSourceSelect from '../../components/ItemSourceSelect';
import ProductList from '../../components/ProductList';

const AdminEditProduct = ({ isNewProduct = false }) => { // Add prop to distinguish new/edit
    const [productData, setProductData] = useState({
        title: '',
        description: '',
        price: '',
        quantity: '',
        itemSource: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProductData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSourceChange = (itemSource) => {
        setProductData((prevData) => ({ ...prevData, itemSource }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await apiRequest('Admin/saveProduct', 'POST', productData);
            alert(response.message);
        } catch (error) {
            console.error('Failed to save product:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleNewProduct = () => {
        setProductData({
            title: '',
            description: '',
            price: '',
            quantity: '',
            itemSource: '',
        });
        navigate('/admin/editproduct/new'); // Navigate to a new product route
    };

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6">{isNewProduct ? 'Add New Product' : 'Edit Product'}</h1>

            <button
                onClick={handleNewProduct}
                className="mb-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
            >
                New Product
            </button>

            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label htmlFor="title" className="block text-gray-700 font-semibold">Title</label>
                    <input
                        type="text"
                        id="title"
                        name="title"
                        value={productData.title}
                        onChange={handleInputChange}
                        className="w-full border px-3 py-2 rounded-md"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="description" className="block text-gray-700 font-semibold">Description</label>
                    <textarea
                        id="description"
                        name="description"
                        value={productData.description}
                        onChange={handleInputChange}
                        className="w-full border px-3 py-2 rounded-md"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="price" className="block text-gray-700 font-semibold">Price</label>
                    <input
                        type="number"
                        id="price"
                        name="price"
                        value={productData.price}
                        onChange={handleInputChange}
                        className="w-full border px-3 py-2 rounded-md"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="quantity" className="block text-gray-700 font-semibold">Quantity</label>
                    <input
                        type="number"
                        id="quantity"
                        name="quantity"
                        value={productData.quantity}
                        onChange={handleInputChange}
                        className="w-full border px-3 py-2 rounded-md"
                    />
                </div>

                {/* Item Source Dropdown */}
                <div className="mb-4">
                    <ItemSourceSelect
                        selectedSource={productData.itemSource}
                        onChange={handleSourceChange}
                    />
                </div>

                <button
                    type="submit"
                    className={`w-full py-2 px-4 rounded-md text-white ${isLoading ? 'bg-gray-400' : 'bg-blue-500 hover:bg-blue-600'}`}
                    disabled={isLoading}
                >
                    {isLoading ? 'Saving...' : 'Save Product'}
                </button>
            </form>

            {/* Product Listing Section */}
            <ProductList />
        </div>
    );
};

export default AdminEditProduct;
