// components/ProductList.js
import React, { useEffect, useState } from 'react';
import { apiRequest } from '../utils/Api';
import { Link } from 'react-router-dom';

const ProductList = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch all products
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const data = await apiRequest('Marketplace/list', 'POST');
                setProducts(data);
            } catch (error) {
                console.error('Error fetching products:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    if (loading) {
        return <div className="text-center py-10">Loading products...</div>;
    }

    return (
        <div className="container mx-auto px-4 py-10">
            <h2 className="text-2xl font-semibold mb-6">Available Products</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {products.map((product) => (
                    <div key={product.id} className="bg-white shadow-md rounded-lg overflow-hidden flex flex-col h-full">
                        <img
                            src={product.image.replace('img/', 'images/') || 'https://via.placeholder.com/300x200'}
                            alt={product.title}
                            className="w-full h-40 object-cover"
                        />
                        <div className="p-4 flex flex-col justify-between flex-grow">
                            <div className="flex-grow">
                                <h3 className="text-lg font-bold">{product.title}</h3>
                                <p className="text-gray-200">{product.description}</p>
                            </div>
                            <div className="mt-4">
                                <p className="text-sm text-gray-500">Price: €{product.price}</p>
                                <Link
                                    to={`/admin/editproduct/${product.itemSource}-${product.itemId}`}
                                    className="mt-2 w-full bg-indigo-600 text-white py-2 rounded-md hover:bg-indigo-700 text-center inline-block"
                                >
                                    <i className="fas fa-edit"></i> Edit Product
                                </Link>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProductList;
