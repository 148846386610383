import React from "react";
import { Link, useLocation } from "react-router-dom";

const PaymentHistory = () => {
    const location = useLocation();
    const cardType = location.state?.cardType || "Unknown Card"; // Retrieve card type from route state

    // Mock payment history data
    const paymentHistory = [
        { id: 1, date: "2023-12-01", amount: "€150.00", description: "Amazon Purchase" },
        { id: 2, date: "2023-11-28", amount: "€14.95", description: "Netflix Subscription" },
        { id: 3, date: "2023-11-20", amount: "€75.00", description: "Grocery Store" },
    ];

    return (
        <div className="bg-gray-900 text-white min-h-screen p-6">
            <h1 className="text-3xl font-bold mb-4">{cardType} Payment History</h1>
            <Link to="/dashboard" className="text-customOrange hover:text-customDarkBrown underline mb-6 block">
                Back to Dashboard
            </Link>
            <div className="bg-gray-800 p-4 rounded-lg">
                {paymentHistory.map((payment) => (
                    <div key={payment.id} className="p-3 border-b border-gray-700">
                        <p className="text-lg font-semibold">{payment.description}</p>
                        <p className="text-sm text-gray-400">{payment.date}</p>
                        <p className="text-xl font-bold">{payment.amount}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PaymentHistory;
