import React from 'react';
import AssetsSection from '../components/AssetsSection';

const Assets = () => {
    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold mb-6">Your Assets</h1>
            <AssetsSection showKycNotification={false} />
        </div>
    );
};

export default Assets;
