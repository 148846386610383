import React, { useState, useEffect } from 'react';
import { apiRequest } from '../utils/Api'; // Assuming you have this utility for making API calls
import { useNavigate } from 'react-router-dom';

const AccountActivation = () => {
    const [tokens, setTokens] = useState(0);
    const [selectedLevel, setSelectedLevel] = useState(''); // Track which level user selects (Plus or Pro)
    const [finalPrice, setFinalPrice] = useState({ plus: 60, pro: 120 });
    const [tokenDiscount, setTokenDiscount] = useState({ plus: 0, pro: 0 });
    const navigate = useNavigate();

    useEffect(() => {
        // Get the token balance from localStorage
        const tokenBalance = parseInt(localStorage.getItem('tokens'), 10) || 0;
        setTokens(tokenBalance);

        // Calculate token discount for Plus (max 30 Euro)
        const maxPlusDiscount = Math.min(tokenBalance, 30);
        const plusPriceAfterDiscount = 60 - maxPlusDiscount;

        // Calculate token discount for Pro (max 30 Euro)
        const maxProDiscount = Math.min(tokenBalance, 30);
        const proPriceAfterDiscount = 120 - maxProDiscount;

        setFinalPrice({ plus: plusPriceAfterDiscount, pro: proPriceAfterDiscount });
        setTokenDiscount({ plus: maxPlusDiscount, pro: maxProDiscount });
    }, []);

    const handleActivation = async (level) => {
        const itemId = level === 'plus' ? 4 : 3; // 4-2 for Plus, 4-3 for Pro
        const price = finalPrice[level];

        try {
            const response = await apiRequest('Marketplace/activateAccount', 'POST', {
                itemSource: 4,
                itemId,
                price,
            });

            if (response.success) {
                alert('Account activated successfully!');
                navigate('/dashboard'); // Redirect to dashboard or desired page
            } else {
                alert('Activation failed. Please try again.');
            }
        } catch (error) {
            console.error('Error activating account:', error);
            alert('Activation failed due to a server error.');
        }
    };

    return (
        <div className="container mx-auto my-8 p-6">
            <h1 className="text-3xl font-bold text-center mb-6">Activate Your Account</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {/* Plus Plan */}
                <div className="bg-white p-6 shadow-lg rounded-lg">
                    <h2 className="text-2xl font-semibold text-center mb-4">Plus Plan</h2>
                    <p className="text-center mb-4">Unlock all basic features and access trading at lower limits.</p>
                    <ul className="list-disc list-inside mb-4">
                        <li>Access to bonds and stock trading</li>
                        <li>Basic customer support</li>
                        <li>2% Trading fee</li>
                    </ul>
                    <div className="text-center">
                        <p className="text-lg mb-2">
                            <strong>Price:</strong> €60
                        </p>
                        <p className="text-sm mb-2">
                            <strong>Discount with Tokens:</strong> -€{tokenDiscount.plus} ({tokens} tokens available)
                        </p>
                        <p className="text-xl font-bold mb-4">Final Price: €{finalPrice.plus}</p>
                        <button
                            onClick={() => handleActivation('plus')}
                            className={`w-full py-2 px-4 bg-green-600 text-white rounded-md ${finalPrice.plus > 0 ? '' : 'opacity-50 cursor-not-allowed'}`}
                            disabled={finalPrice.plus > 0 ? false : true}
                        >
                            Activate Plus
                        </button>
                    </div>
                </div>

                {/* Pro Plan */}
                <div className="bg-white p-6 shadow-lg rounded-lg">
                    <h2 className="text-2xl font-semibold text-center mb-4">Pro Plan</h2>
                    <p className="text-center mb-4">Unlock all premium features and access advanced trading options.</p>
                    <ul className="list-disc list-inside mb-4">
                        <li>Everything in Plus</li>
                        <li>Priority customer support</li>
                        <li>Exclusive offers on bonds and stocks</li>
                        <li>Advanced reporting tools</li>
                        <li>1.5% Trading fee</li>
                    </ul>
                    <div className="text-center">
                        <p className="text-lg mb-2">
                            <strong>Price:</strong> €120
                        </p>
                        <p className="text-sm mb-2">
                            <strong>Discount with Tokens:</strong> -€{tokenDiscount.pro} ({tokens} tokens available)
                        </p>
                        <p className="text-xl font-bold mb-4">Final Price: €{finalPrice.pro}</p>
                        <button
                            onClick={() => handleActivation('pro')}
                            className={`w-full py-2 px-4 bg-blue-600 text-white rounded-md ${finalPrice.pro > 0 ? '' : 'opacity-50 cursor-not-allowed'}`}
                            disabled={finalPrice.pro > 0 ? false : true}
                        >
                            Activate Pro
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountActivation;
