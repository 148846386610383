import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiRequest } from '../utils/Api';
import PaymentMethod from '../components/PaymentMethod'; // Import the new PaymentMethod component

const Checkout = () => {
    const [cartItems, setCartItems] = useState([]);
    const [cartTotal, setCartTotal] = useState(0);
    const [transactionId, setTransactionId] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCartItems = async () => {
            try {
                const data = await apiRequest('ShoppingCart/getItems', 'POST');
                setCartItems(data);
                setCartTotal(data.reduce((total, item) => total + item.price * item.quantity, 0));
                setLoading(false);
            } catch (error) {
                console.error('Error loading cart items:', error);
            }
        };

        fetchCartItems();
    }, []);

    const handleCheckoutSubmit = async (e) => {
        e.preventDefault();

        if (!transactionId) {
            alert('Please complete the payment process.');
            return;
        }

        const response = await apiRequest('Cashier/completePayment', 'POST', {
            transactionId: transactionId,
            amount: cartTotal
        });

        if (response.success) {
            navigate('/thankyou/' + transactionId);
        } else {
            alert(`Error completing payment: ${response.error}`);
        }
    };

    const handlePaymentSuccess = (id) => {
        setTransactionId(id); // Capture the transaction ID from the PaymentMethod component
    };

    const renderCartSummary = () => {
        return cartItems.map((item) => (
            <li key={item.itemId} className="flex justify-between py-2">
                <div>
                    <h6 className="font-bold">{item.name}</h6>
                    <small className="text-gray-500">x{item.quantity}</small>
                </div>
                <span className="text-gray-700">€{(item.price * item.quantity).toFixed(2)}</span>
            </li>
        ));
    };

    if (loading) return <div>Loading...</div>;

    return (
        <div className="container mx-auto mt-8">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <div className="md:col-span-2">
                    <h1 className="text-2xl font-semibold mb-6">Checkout</h1>
                    <form onSubmit={handleCheckoutSubmit}>
                        {/* Use PaymentMethod component */}
                        <PaymentMethod
                            cartTotal={cartTotal}
                            onPaymentSuccess={handlePaymentSuccess}
                            allowedMethods={[]} // Can be passed empty or with specific allowed methods
                        />

                        <button type="submit" className="mt-6 bg-customOrange hover:bg-customDarkBrown text-white px-4 py-2 rounded-md">
                            Complete Checkout
                        </button>
                    </form>
                </div>

                <div>
                    <h4 className="text-xl font-semibold mb-4">Your Cart</h4>
                    <ul className="border-t border-b py-4 space-y-4">
                        {renderCartSummary()}
                    </ul>
                    <div className="border-t pt-4 mt-4">
                        <span className="font-bold">Total (EUR):</span>
                        <span className="float-right">€{cartTotal.toFixed(2)}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Checkout;
