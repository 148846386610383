export const handleFile = (file) => {
    return new Promise((resolve, reject) => {
        if (!file) resolve(null);

        let reader = new FileReader();
        reader.onload = function (event) {
            let img = new Image();
            img.onload = function () {
                const MAX_WIDTH = 2000;
                const MAX_HEIGHT = 2000;
                let width = img.width;
                let height = img.height;

                // Resize image if larger than max dimensions
                if (width > MAX_WIDTH || height > MAX_HEIGHT) {
                    if (width > height) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    } else {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }

                // Create a canvas element to convert the image to webp format
                let canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = height;
                let ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);

                // Convert the canvas to base64 webp data URL
                let dataURL = canvas.toDataURL('image/webp');
                resolve(dataURL);
            };
            img.src = event.target.result;
        };
        reader.readAsDataURL(file);
    });
};
