import React, { useState, useRef, useEffect } from 'react';
import { apiRequest } from '../../utils/Api'; // Replace with your actual API utility

const AdminUserEditor = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [formData, setFormData] = useState({});
    const dropdownRef = useRef(null); // Ref to handle outside clicks

    // Handle search input change
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        if (e.target.value.length > 2) {
            searchUsers(e.target.value);
        } else {
            setSearchResults([]);
        }
    };

    // Search users
    const searchUsers = async (query) => {
        try {
            const data = await apiRequest('Admin/userSearch', 'POST', { query });
            if (data.success && data.users) {
                setSearchResults(data.users);
            } else {
                setSearchResults([]);
            }
        } catch (error) {
            console.error('Error searching users:', error);
        }
    };

    // Populate form fields with the selected user's data
    const handleUserSelect = (user) => {
        setSelectedUser(user);
        setFormData({
            level: user.level || '1',
            email: user.email || '',
            displayName: user.displayName || '',
            firstName: user.firstName || '',
            middleName: user.middleName || '',
            lastName: user.lastName || '',
            lang: user.lang || 'en_US',
            verified: user.verified || '0',
            status: user.status || '1',
            validEmail: user.validEmail || '0',
            provider: user.provider || 'local',
        });
        setSearchResults([]); // Clear the search results after selection
    };

    // Handle input change in form
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    // Handle form submission (saving user data)
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await apiRequest('Admin/userSave', 'POST', { data: formData });
            if (response.success) {
                alert('User saved successfully!');
            } else {
                alert('Error saving user.');
            }
        } catch (error) {
            console.error('Error saving user:', error);
        }
    };

    // Detect clicks outside the search input and dropdown
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setSearchResults([]); // Close the dropdown
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <div className="container mx-auto mt-5">
            <h2 className="text-2xl font-semibold mb-4">User Editor</h2>
            <p className="mb-4">
                To request a new password, use the password{' '}
                <a href="/reset" className="text-blue-600 underline">reset form</a>
            </p>

            {/* Search Field */}
            <div className="relative mb-4" ref={dropdownRef}>
                <label htmlFor="searchUser" className="block text-sm font-medium text-gray-700">Search User</label>
                <input
                    type="text"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                    id="searchUser"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder="Enter email, username, or other identifier"
                />
                <div className="absolute left-0 w-full bg-white border border-gray-300 rounded-md mt-1 z-10">
                    {searchResults.length > 0 ? (
                        searchResults.map((user) => (
                            <button
                                key={user.id}
                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                onClick={() => handleUserSelect(user)}
                            >
                                {user.displayName} ({user.email})
                            </button>
                        ))
                    ) : searchTerm.length > 2 ? (
                        <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 bg-gray-100">
                            No users found
                        </button>
                    ) : null}
                </div>
            </div>

            {selectedUser && (
                <form id="userEditorForm" onSubmit={handleSubmit}>
                    {/* User Fields */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        {/* Level */}
                        <div>
                            <label htmlFor="level" className="block text-sm font-medium text-gray-700">Level</label>
                            <select
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                id="level"
                                name="level"
                                value={formData.level}
                                onChange={handleChange}
                                required
                            >
                                <option value="1">User</option>
                                <option value="2">Moderator</option>
                                <option value="3">Admin</option>
                                <option value="200">Super Admin</option>
                            </select>
                        </div>

                        {/* Email */}
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                            <input
                                type="email"
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>

                    {/* Display Name & First Name */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div>
                            <label htmlFor="displayName" className="block text-sm font-medium text-gray-700">Display Name</label>
                            <input
                                type="text"
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                id="displayName"
                                name="displayName"
                                value={formData.displayName}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div>
                            <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First Name</label>
                            <input
                                type="text"
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                id="firstName"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>

                    {/* Middle Name & Last Name */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div>
                            <label htmlFor="middleName" className="block text-sm font-medium text-gray-700">Middle Name</label>
                            <input
                                type="text"
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                id="middleName"
                                name="middleName"
                                value={formData.middleName}
                                onChange={handleChange}
                            />
                        </div>

                        <div>
                            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last Name</label>
                            <input
                                type="text"
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                id="lastName"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>

                    {/* Lang & Provider */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div>
                            <label htmlFor="lang" className="block text-sm font-medium text-gray-700">Language</label>
                            <select
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                id="lang"
                                name="lang"
                                value={formData.lang}
                                onChange={handleChange}
                                required
                            >
                                <option value="en_US">English (US)</option>
                            </select>
                        </div>

                        <div>
                            <label htmlFor="provider"
                                   className="block text-sm font-medium text-gray-700">Provider</label>
                            <select
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                id="provider"
                                name="provider"
                                value={formData.provider}
                                onChange={handleChange}
                                required
                            >
                                <option value="local">E-mail login</option>
                                <option value="google">Google</option>
                                <option value="facebook">Facebook</option>
                            </select>

                        </div>
                    </div>

                    {/* Status & Verified */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div>
                            <label htmlFor="status" className="block text-sm font-medium text-gray-700">Status</label>
                            <select
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                id="status"
                                name="status"
                                value={formData.status}
                                onChange={handleChange}
                                required
                            >
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                            </select>
                        </div>

                        <div>
                            <label htmlFor="verified" className="block text-sm font-medium text-gray-700">Verified</label>
                            <select
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                id="verified"
                                name="verified"
                                value={formData.verified}
                                onChange={handleChange}
                                required
                            >
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                    </div>

                    {/* Provider ID & Valid Email */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div>
                            <label htmlFor="validEmail" className="block text-sm font-medium text-gray-700">Valid Email</label>
                            <select
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                id="validEmail"
                                name="validEmail"
                                value={formData.validEmail}
                                onChange={handleChange}
                                required
                            >
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                    </div>

                    {/* Submit Button */}
                    <div>
                        <button
                            type="submit"
                            className="w-full bg-orange-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50"
                        >
                            Save User
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default AdminUserEditor;
