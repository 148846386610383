import React from 'react';

const LegalPage = ({ title, content }) => {
    return (
        <div className="container mx-auto py-10 px-6">
            <h1 className="text-3xl font-bold mb-6 text-center">{title}</h1>
            <div className="prose max-w-full text-gray-700">
                {content.map((section, index) => (
                    <div key={index} className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">{section.heading}</h2>
                        {section.text && <p className="mb-4">{section.text}</p>}
                        {section.subSections && (
                            <ul className="list-disc pl-5">
                                {section.subSections.map((sub, idx) => (
                                    <li key={idx} className="mb-2">
                                        <strong>{sub.title}:</strong> {sub.text}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

// Example content for the Cookie Policy
const cookiePolicyContent = [
    {
        heading: '1. Introduction',
        text: 'This Cookie Policy ("Policy") explains how Mstrpayment AB ("we," "us," or "our") uses cookies and similar technologies, such as local storage, on our service, Mstrpayment, available at Mstrpayment.com (the "Service"). By using our Service, you agree to the use of cookies and local storage in accordance with this Policy.'
    },
    {
        heading: '2. What Are Cookies?',
        text: 'Cookies are small text files that are placed on your device (computer, tablet, smartphone) when you visit a website. Cookies are widely used to make websites work, or work more efficiently, as well as to provide information to the owners of the site.'
    },
    {
        heading: '3. What Is Local Storage?',
        text: 'Local storage is a web storage method that allows websites to store data directly on a user’s device. Unlike cookies, the data in local storage is not automatically transmitted to the server with every HTTP request, making it more persistent and providing more storage space.'
    },
    {
        heading: '4. How We Use Cookies and Local Storage',
        subSections: [
            { title: '4.1 Essential Cookies', text: 'These cookies are necessary for the proper functioning of our Service. They allow you to navigate our site and use essential features, such as accessing secure areas of the site.' },
            { title: '4.2 Performance and Analytics Cookies', text: 'We use these cookies to collect information about how you use our Service, such as which pages you visit most often and if you receive error messages. The information collected is aggregated and used to improve the functionality of our Service.' },
            { title: '4.3 Functional Cookies', text: 'These cookies allow our Service to remember choices you make (such as your username, language, or the region you are in) and provide enhanced, more personalized features.' },
            { title: '4.4 Advertising and Targeting Cookies', text: 'We may use these cookies to deliver content that is more relevant to you and your interests.' }
        ]
    },
    {
        heading: '5. Managing Cookies and Local Storage',
        subSections: [
            { title: '5.1 Browser Settings', text: 'Most web browsers automatically accept cookies, but you can modify your browser settings to decline cookies if you prefer.' },
            { title: '5.2 Consent Management', text: 'When you first visit our Service, you will see a cookie consent banner. You can choose to accept or decline non-essential cookies through this banner.' }
        ]
    },
    {
        heading: '6. Third-Party Cookies',
        text: 'Some cookies on our Service may be placed by third parties, such as Google, Facebook, and Stripe, for purposes such as providing log-in functionality, processing payments, or delivering targeted advertising.'
    },
    {
        heading: '7. Changes to This Policy',
        text: 'We may update this Cookie Policy from time to time. We will notify you of any significant changes by posting the new Policy on this page.'
    },
    {
        heading: '8. Contact Information',
        text: 'If you have any questions or concerns about this Cookie Policy or our use of cookies and local storage, please contact us at: info@mstrpayment.com'
    }
];

// Example content for the Privacy Policy
const privacyPolicyContent = [
    {
        heading: '1. Introduction',
        text: 'This Privacy Policy ("Policy") explains how Mstrpayment AB ("we," "us," or "our") collects, uses, and protects your personal information when you use our service, Mstrpayment, on the domain mstrpayment.com (the "Service"). We are committed to protecting your privacy and ensuring that your personal data is handled in compliance with applicable data protection laws.'
    },
    {
        heading: '2. Data Controller',
        text: 'The data controller responsible for the processing of your personal data is Mattias Ane, located at Lutabäcksvägen 3 c, 703 75 Örebro, Sweden. You can contact the data controller at info@mstrpayment.com.'
    },
    {
        heading: '3. Information We Collect',
        subSections: [
            { title: 'Contact Information', text: 'Name, email address, phone number.' },
            { title: 'Account Information', text: 'Username, password, and other registration details.' },
            { title: 'Transaction Data', text: 'Details of the transactions you perform using our Service.' }
        ]
    },
    {
        heading: '4. How We Use Your Information',
        text: 'We use your personal data for the following purposes: To provide and manage the Service, to process transactions, to communicate with you, to comply with legal obligations, and to improve our Service.'
    },
    {
        heading: '5. Your Rights',
        text: 'Under GDPR, you have the right to access, rectify, delete, or restrict the processing of your data. You also have the right to data portability and to lodge a complaint with your local data protection authority.'
    },
    {
        heading: '6. Contact Information',
        text: 'If you have any questions or concerns about this Privacy Policy, please contact us at: customerservice@mstrpayment.com'
    }
];

// Example content for the Terms of Service
const termsOfServiceContent = [
    {
        heading: '1. Introduction',
        text: 'Welcome to Mstrpayment. These Terms of Service ("Terms") govern your access to and use of the Mstrpayment service provided by Mstrpayment AB ("we," "us," or "our"). By using our Service, you agree to these Terms.'
    },
    {
        heading: '2. Account Registration',
        text: 'To use certain features of the Service, including trading stocks and accessing courses, you must create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.'
    },
    {
        heading: '3. Trading Disclaimer',
        text: 'The Service may provide information regarding stocks and other financial instruments; however, this information is provided for educational purposes only and should not be construed as financial advice.'
    },
    {
        heading: '4. Limitation of Liability',
        text: 'In no event shall Mstrpayment AB or its affiliates be liable for any indirect, incidental, or consequential damages arising from your use of the Service.'
    },
    {
        heading: '5. Contact Information',
        text: 'If you have any questions about these Terms, please contact us at: customerservice@mstrpayment.com'
    }
];

// Rendering individual legal pages
const CookiePolicyPage = () => <LegalPage title="Cookie Policy for Mstrpayment" content={cookiePolicyContent} />;
const PrivacyPolicyPage = () => <LegalPage title="Privacy Policy for Mstrpayment" content={privacyPolicyContent} />;
const TermsOfServicePage = () => <LegalPage title="Terms of Service for Mstrpayment" content={termsOfServiceContent} />;

export default LegalPage;
export { CookiePolicyPage, PrivacyPolicyPage, TermsOfServicePage };
