import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { apiRequest } from '../utils/Api'; // Adjust the path to your API utility

const VerifyEmail = () => {
    const [verificationStatus, setVerificationStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const {hash} = useParams();

    // Capture the hash from the URL
    useEffect(() => {
        if (hash) {
            verifyEmail(hash);
        } else {
            setErrorMessage('No verification hash found.');
            setLoading(false);
        }
    }, []);

    // Function to verify email by hash
    const verifyEmail = async (hash) => {
        try {
            const response = await apiRequest('Auth/verifyEmailByHash', 'POST', { hash });
            if (response.success) {
                setVerificationStatus('success');
                // Optionally, navigate to a different page after success
                setTimeout(() => {
                    navigate('/login'); // Redirect to login after success
                }, 3000); // Redirect after 3 seconds
            } else {
                setVerificationStatus('error');
                setErrorMessage(response.error || 'Failed to verify email.');
            }
        } catch (error) {
            setVerificationStatus('error');
            setErrorMessage('An error occurred while verifying your email.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container mx-auto py-10 text-center">
            {loading ? (
                <div className="text-xl">Verifying your email...</div>
            ) : verificationStatus === 'success' ? (
                <div className="text-xl text-green-500">
                    Email verified successfully! Redirecting to login...
                </div>
            ) : (
                <div className="text-xl text-red-500">
                    {errorMessage}
                </div>
            )}
        </div>
    );
};

export default VerifyEmail;
