import React from 'react';

const MainPage = () => {
    return (
        <div className="h-full w-full">
            <img
                src="/images/1.webp"
                alt="Welcome Section"
                className="w-full h-auto object-contain border-b-10 border-gray-600"
            />
            <img
                src="/images/2.webp"
                alt="Crypto Assets Section"
                className="w-full h-auto object-contain border-b-10 border-gray-600"
            />
            <img
                src="/images/3.webp"
                alt="AI and Costs Section"
                className="w-full h-auto object-contain border-b-10 border-gray-600"
            />
            <div className="flex flex-col items-center justify-center border-b-10 border-gray-600">
                <img
                    src="/images/4.webp"
                    alt="Call to Action Section"
                    className="w-full h-auto object-contain"
                />
                <a
                    href="/register"
                    className="btn w-1/2 bg-customOrange text-center text-white text-lg px-6 py-3 rounded-lg hover:bg-customDarkBrown transition-all mt-6"
                >
                    Sign Up Now
                </a>
            </div>
        </div>
    );
};

export default MainPage;
