import React, { useState } from 'react';
import { apiRequest } from '../utils/Api';
import { handleFile } from '../utils/FileHandler';
import countryList from '../assets/countries.json';
import PaymentMethod from "../components/PaymentMethod";
import IntlTelInput from "intl-tel-input/reactWithUtils";
import "intl-tel-input/build/css/intlTelInput.css";

const KYC = () => {
    const [formData, setFormData] = useState({
        address: '',
        address2: '',
        city: '',
        postcode: '',
        state: '',
        country: '',
        phone: '',
        dob: '',
        pepCheck: '',
        usCitizen: '',
        nationality: '',
        idNumber: '',
        sourceOfFunds: '',
        proofOfAddress: null,
        proofOfId: null,
        proofOfIdTwo: null,
    });
    const [currentStep, setCurrentStep] = useState(1);
    const [progress, setProgress] = useState(33);
    const [otherSourceOfFunds, setOtherSourceOfFunds] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [payAmount, setPayAmount] = useState(30);
    const [transactionId, setTransactionId] = useState('');

    const handleStepChange = (step) => {
        if (validateStep(currentStep)) {
            setCurrentStep(step);
            setProgress((step / 3) * 100);
        }
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevState) => ({ ...prevState, [id]: value }));
    };

    const handlePhoneChange = (isValid, phoneNumber) => {
        if (isValid) {
            setFormData((prevState) => ({ ...prevState, phone: phoneNumber }));
        } else {
            setFormData((prevState) => ({ ...prevState, phone: '' }));
        }
    };

    const handleFileChange = async (e) => {
        const { id } = e.target;
        const file = e.target.files[0];
        const resizedFile = await handleFile(file);
        setFormData((prevState) => ({ ...prevState, [id]: resizedFile }));
    };

    const validateStep = (step) => {
        let isValid = true;
        if (step === 1) {
            if (!formData.address || !formData.city || !formData.postcode || !formData.country || !formData.phone) {
                isValid = false;
            }
        } else if (step === 2) {
            if (!formData.dob || !formData.pepCheck || !formData.usCitizen || !formData.nationality || !formData.idNumber || !formData.sourceOfFunds || !formData.proofOfAddress || !formData.proofOfId) {
                isValid = false;
            }
        }
        return isValid;
    };

    const handlePaymentSuccess = (id) => {
        setTransactionId(id);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateStep(2)) {
            alert("Please fill in all required fields.");
            return;
        }

        if (!transactionId) {
            alert('Please complete the payment process.');
            return;
        }

        setIsSubmitting(true);

        const payload = {
            ...formData,
            sourceOfFunds: formData.sourceOfFunds === 'Other' ? otherSourceOfFunds : formData.sourceOfFunds,
            transactionId,
        };

        try {
            const response = await apiRequest('Auth/RegisterKYC', 'POST', payload);
            if (response.success) {
                localStorage.setItem('kycActive', true);
                localStorage.setItem('kycProcessing', true);
                window.location.href = '/dashboard';
            }
        } catch (error) {
            console.error('KYC submission error:', error);
            alert('Failed to submit the KYC form.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="bg-gray-900 text-white min-h-screen p-6">
            <h1 className="text-3xl font-bold mb-6 text-orange-500">Complete Your KYC</h1>

            {/* Progress Bar */}
            <div className="w-full bg-gray-700 rounded-full h-2 mb-6">
                <div style={{ width: `${progress}%` }} className="bg-orange-500 h-2 rounded-full"></div>
            </div>

            <form onSubmit={handleSubmit}>
                {/* Step 1: Address and Contact Info */}
                {currentStep === 1 && (
                    <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                        <h2 className="text-xl font-semibold mb-4 text-orange-500">Step 1: Address and Contact Info</h2>
                        <div className="mb-4">
                            <label htmlFor="address" className="block text-sm font-medium">Address</label>
                            <input type="text" id="address" value={formData.address} onChange={handleChange} required className="w-full px-3 py-2 mt-1 rounded-lg bg-gray-700 text-white" />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="city" className="block text-sm font-medium">City</label>
                            <input type="text" id="city" value={formData.city} onChange={handleChange} required className="w-full px-3 py-2 mt-1 rounded-lg bg-gray-700 text-white" />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="country" className="block text-sm font-medium">Country</label>
                            <select id="country" value={formData.country} onChange={handleChange} required className="w-full px-3 py-2 mt-1 rounded-lg bg-gray-700 text-white">
                                <option value="">Select a country</option>
                                {countryList.map((country) => (
                                    <option key={country.cca} value={country.cca}>{country.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="phone" className="block text-sm font-medium">Phone Number</label>
                            <IntlTelInput
                                onPhoneNumberChange={handlePhoneChange}
                                preferredCountries={['us', 'gb', 'ca']}
                                defaultCountry="auto"
                                geoIpLookup={(callback) => {
                                    fetch("https://ipinfo.io/?token=240df7ebaef2f4", { mode: "cors" })
                                        .then((resp) => resp.json())
                                        .then((resp) => callback(resp.country));
                                }}
                                inputProps={{ "className": "w-full px-3 py-2 mt-1 rounded-lg bg-gray-700 text-white" }}
                            />
                        </div>
                    </div>
                )}

                {/* Step 2: KYC Information */}
                {currentStep === 2 && (
                    <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                        <h2 className="text-xl font-semibold mb-4 text-orange-500">Step 2: KYC Information</h2>
                        <div className="mb-4">
                            <label htmlFor="dob" className="block text-sm font-medium">Date of Birth</label>
                            <input type="date" id="dob" value={formData.dob} onChange={handleChange} required className="w-full px-3 py-2 mt-1 rounded-lg bg-gray-700 text-white" />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="proofOfAddress" className="block text-sm font-medium">Proof of Address</label>
                            <input type="file" id="proofOfAddress" onChange={handleFileChange} required className="w-full px-3 py-2 mt-1 rounded-lg bg-gray-700 text-white" />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="proofOfId" className="block text-sm font-medium">Proof of ID</label>
                            <input type="file" id="proofOfId" onChange={handleFileChange} required className="w-full px-3 py-2 mt-1 rounded-lg bg-gray-700 text-white" />
                        </div>
                    </div>
                )}

                {/* Step 3: Payment */}
                {currentStep === 3 && (
                    <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                        <h2 className="text-xl font-semibold mb-4 text-orange-500">Step 3: Payment</h2>
                        <PaymentMethod
                            cartTotal={payAmount.toFixed(2)}
                            onPaymentSuccess={handlePaymentSuccess}
                            allowedMethods={['BTC', 'DOGE', 'BYPASS']}
                        />
                    </div>
                )}

                {/* Navigation Buttons */}
                <div className="flex justify-between mt-6">
                    {currentStep > 1 && (
                        <button type="button" onClick={() => handleStepChange(currentStep - 1)} className="px-4 py-2 bg-gray-700 text-white rounded-lg">
                            Previous
                        </button>
                    )}
                    {currentStep < 3 && (
                        <button type="button" onClick={() => handleStepChange(currentStep + 1)} className="px-4 py-2 bg-orange-500 text-white rounded-lg">
                            Next
                        </button>
                    )}
                    {currentStep === 3 && (
                        <button type="submit" className="px-4 py-2 bg-green-500 text-white rounded-lg" disabled={isSubmitting}>
                            {isSubmitting ? "Submitting..." : "Submit"}
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
};

export default KYC;
