import React, { useState, useEffect } from 'react';
import { apiRequest } from '../../utils/Api'; // Adjust the path based on your folder structure

const AdminPromotions = () => {
    const [promotions, setPromotions] = useState([]);
    const [newPromotion, setNewPromotion] = useState({
        promotion: '',
        logo: '',
        description: '',
        identifier: ''
    });
    const [isEditing, setIsEditing] = useState(false);
    const [editingPromotion, setEditingPromotion] = useState(null);

    // Fetch Promotions
    const loadPromotions = async () => {
        try {
            const data = await apiRequest('Admin/listPromotions', 'POST');
            setPromotions(data);
        } catch (error) {
            console.error('Error loading promotions:', error);
        }
    };

    useEffect(() => {
        loadPromotions();
    }, []);

    // Handle Form Input Change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewPromotion((prev) => ({ ...prev, [name]: value }));
    };

    // Handle Create New Promotion
    const handleCreatePromotion = async (e) => {
        e.preventDefault();
        try {
            const response = await apiRequest('Admin/createPromotion', 'POST', newPromotion);
            if (response.status === 'success') {
                setNewPromotion({ promotion: '', logo: '', description: '', identifier: '' });
                loadPromotions(); // Reload promotions after creating a new one
                alert(response.message);
            } else {
                alert(response.message);
            }
        } catch (error) {
            console.error('Error creating promotion:', error);
        }
    };

    // Handle Edit Promotion (sets promotion in edit mode)
    const handleEditPromotion = (promotion) => {
        setIsEditing(true);
        setEditingPromotion(promotion);
        setNewPromotion(promotion);
    };

    // Handle Update Promotion
    const handleUpdatePromotion = async (e) => {
        e.preventDefault();
        try {
            const response = await apiRequest('Admin/updatePromotion', 'POST', {
                ...newPromotion,
                id: editingPromotion.id
            });
            if (response.status === 'success') {
                setIsEditing(false);
                setEditingPromotion(null);
                setNewPromotion({ promotion: '', logo: '', description: '', identifier: '' });
                loadPromotions(); // Reload promotions after update
                alert(response.message);
            } else {
                alert(response.message);
            }
        } catch (error) {
            console.error('Error updating promotion:', error);
        }
    };

    // Handle Delete Promotion
    const handleDeletePromotion = async (id) => {
        if (!window.confirm('Are you sure you want to delete this promotion?')) return;
        try {
            const response = await apiRequest('Admin/deletePromotion', 'POST', { id });
            if (response.status === 'success') {
                loadPromotions(); // Reload promotions after deletion
                alert(response.message);
            } else {
                alert(response.message);
            }
        } catch (error) {
            console.error('Error deleting promotion:', error);
        }
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold mb-6">Promotions tool</h1>

            {/* Promotion Form */}
            <form onSubmit={isEditing ? handleUpdatePromotion : handleCreatePromotion} className="mb-8">
                <div className="mb-4">
                    <label className="block text-gray-700">Promotion Name</label>
                    <input
                        type="text"
                        name="promotion"
                        value={newPromotion.promotion}
                        onChange={handleInputChange}
                        className="w-full border p-2 rounded-md"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Logo URL</label>
                    <input
                        type="text"
                        name="logo"
                        value={newPromotion.logo}
                        onChange={handleInputChange}
                        className="w-full border p-2 rounded-md"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Description</label>
                    <textarea
                        name="description"
                        value={newPromotion.description}
                        onChange={handleInputChange}
                        className="w-full border p-2 rounded-md"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Identifier</label>
                    <input
                        type="text"
                        name="identifier"
                        value={newPromotion.identifier}
                        onChange={handleInputChange}
                        className="w-full border p-2 rounded-md"
                        required
                    />
                </div>
                <button
                    type="submit"
                    className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                >
                    {isEditing ? 'Update Promotion' : 'Create Promotion'}
                </button>
            </form>

            {/* Promotions List */}
            <div className="overflow-x-auto">
                <table className="table-auto w-full border-collapse border">
                    <thead>
                    <tr className="bg-gray-100">
                        <th className="border p-2">ID</th>
                        <th className="border p-2">Promotion</th>
                        <th className="border p-2">Logo</th>
                        <th className="border p-2">Description</th>
                        <th className="border p-2">Identifier</th>
                        <th className="border p-2">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {promotions.map((promo) => (
                        <tr key={promo.id}>
                            <td className="border p-2">{promo.id}</td>
                            <td className="border p-2">{promo.promotion}</td>
                            <td className="border p-2">
                                <img src={promo.logo} alt={promo.promotion} className="w-16 h-16" />
                            </td>
                            <td className="border p-2">{promo.description}</td>
                            <td className="border p-2">{promo.identifier}</td>
                            <td className="border p-2">
                                <button
                                    onClick={() => handleEditPromotion(promo)}
                                    className="bg-yellow-500 text-white px-4 py-2 rounded-md mr-2 hover:bg-yellow-600"
                                >
                                    Edit
                                </button>
                                <button
                                    onClick={() => handleDeletePromotion(promo.id)}
                                    className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AdminPromotions;
