import React from 'react';
import {Link} from "react-router-dom";

const Loans = () => {
    // Mock data for loans
    const loans = [
        {
            id: 1,
            accountNumber: 'NP 3874825',
            outstandingAmount: '€50',
            nextPaymentDate: '2024-01-15',
            duration: '12 months',
        },
        {
            id: 2,
            accountNumber: 'NP 4278493',
            outstandingAmount: '€20',
            nextPaymentDate: '2024-01-20',
            duration: '18 months',
        },
        {
            id: 3,
            accountNumber: 'NP 8493021',
            outstandingAmount: '€30',
            nextPaymentDate: '2024-02-01',
            duration: '6 months',
        },
    ];

    return (
        <div className="bg-gray-900 text-white min-h-screen p-6">
            <h1 className="text-3xl font-bold mb-6 text-orange-500">Current Loans</h1>
            <Link to="/dashboard" className="text-customOrange hover:text-customDarkBrown underline mb-6 block">
                Back to Dashboard
            </Link>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {loans.map((loan) => (
                    <div key={loan.id} className="bg-gray-800 p-6 rounded-lg shadow-lg">
                        <h2 className="text-xl font-semibold mb-2">Loan #{loan.id}</h2>
                        <p><strong>Account Number:</strong> {loan.accountNumber}</p>
                        <p><strong>Outstanding Amount:</strong> {loan.outstandingAmount}</p>
                        <p><strong>Next Payment Date:</strong> {loan.nextPaymentDate}</p>
                        <p><strong>Duration:</strong> {loan.duration}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Loans;
