import React, { useState, useEffect } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { apiRequest } from '../utils/Api'; // Assuming API request helper

const PaymentMethod = ({ cartTotal, onPaymentSuccess, allowedMethods = [] }) => {
    const [paymentMethod, setPaymentMethod] = useState('');
    const [cryptoInfo, setCryptoInfo] = useState(null);
    const [transactionId, setTransactionId] = useState('');
    const [intervalId, setIntervalId] = useState(null); // For managing the payment status check interval
    const [countdown, setCountdown] = useState(30); // For countdown timer
    const [countDownCounter, setCountDownCounter] = useState(10); // For countdown timer
    const [buttonDisabled, setButtonDisabled] = useState(true); // To disable the button until confirmations are met

    const defaultMethods = [
        { label: 'Bitcoin (BTC)', value: 'BTC' },
        { label: 'Litecoin (LTC)', value: 'LTC' },
        { label: 'Dogecoin (DOGE)', value: 'DOGE' },
        { label: 'Bypass Payment (for testing)', value: 'BYPASS' },
    ];

    const methodsToRender = allowedMethods.length > 0
        ? defaultMethods.filter(method => allowedMethods.includes(method.value))
        : defaultMethods;

    // Handle Payment Method Selection and fetch payment details
    const handlePaymentMethodChange = async (e) => {
        const method = e.target.value;
        setPaymentMethod(method);
        const amount = cartTotal;

        if (['BTC', 'LTC', 'DOGE', 'BYPASS'].includes(method)) {
            try {
                const response = await apiRequest('Cashier/startPayment', 'POST', { paymentMethod: method, amount: amount, kycPayment: 1 });
                const paymentInfo = response.paymentInfo;

                if (paymentInfo.success) {
                    setCryptoInfo({
                        address: paymentInfo.address,
                        amount: paymentInfo.amount,
                        currency: paymentInfo.currency,
                        timeout: paymentInfo.timeout / 60, // Convert seconds to minutes
                        verifications: paymentInfo.verifications,
                        count: paymentInfo.count,
                        txId: paymentInfo.txId,
                        note: paymentInfo.note,
                        qr: paymentInfo.qr,
                    });
                    setTransactionId(response.transactionId);
                    setButtonDisabled(true); // Disable button initially

                    // Start polling the payment status every 30 seconds
                    const id = setInterval(() => checkPaymentStatus(method, paymentInfo.address), 30000);
                    setIntervalId(id); // Store interval ID

                    // Start the countdown timer
                    startCountdown();
                } else {
                    alert(`Checkout failed: ${paymentInfo.error}`);
                }
            } catch (error) {
                console.error('Error starting payment:', error);
            }
        } else {
            setCryptoInfo(null); // Clear crypto info for non-crypto payments
        }
    };

    // Start the countdown for 30 seconds and update every second
    const startCountdown = () => {
        setCountdown(countDownCounter); // Reset countdown to 30 seconds
        const countdownInterval = setInterval(() => {
            setCountdown(prevCountdown => {
                if (prevCountdown > 1) {
                    return prevCountdown - 1;
                } else {
                    clearInterval(countdownInterval);
                    return countDownCounter; // Reset countdown for the next interval
                }
            });
        }, 1000);
    };

    // Check payment status every 30 seconds
    const checkPaymentStatus = async (method, address) => {
        try {
            let statusCheck = document.getElementById('statusCheck');
            statusCheck.style.display = 'block'; // Show status check
            statusCheck.innerHTML = 'Checking payment status...';

            const statusResponse = await apiRequest('/Cashier/checkStatus', 'POST', { paymentMethod: method, address });
            const { status } = statusResponse;
            console.log(status);
            if (status && status.confirmations >= cryptoInfo.verifications) {
                // Stop polling and enable the button when enough confirmations are received
                clearInterval(intervalId);
                setButtonDisabled(false); // Enable the "Pay & Submit" button
                onPaymentSuccess(transactionId); // Notify parent of success
                if (statusCheck) {
                    statusCheck.style.display = 'none';
                }
            } else {
                startCountdown(); // Restart countdown after each status check
            }
        } catch (error) {
            console.error('Error checking payment status:', error);
        }
    };

    // Cleanup interval on component unmount
    useEffect(() => {
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [intervalId]);

    return (
        <div className="flex flex-col">
            <div className="flex-grow">
                {/* Dropdown for Payment Method */}
                <div className="mb-4">
                    <label htmlFor="paymentMethod" className="block text-sm font-medium text-gray-700">
                        Payment Method
                    </label>
                    <select
                        id="paymentMethod"
                        name="paymentMethod"
                        value={paymentMethod}
                        onChange={handlePaymentMethodChange}
                        required
                        className="block w-full border rounded-md p-2 mt-1"
                    >
                        <option value="">Select a payment method</option>
                        {methodsToRender.map((method) => (
                            <option key={method.value} value={method.value}>
                                {method.label}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Display Crypto Payment Information */}
                {cryptoInfo && (
                    <div className="mt-6">
                        <h3 className="text-lg font-semibold">Payment Instructions</h3>
                        <p className="text-gray-700">Send the exact amount to the address below:</p>
                        <div className="p-4 border rounded-md bg-gray-50 mt-4">
                            <strong>Payment Address:</strong> {cryptoInfo.address}<br/>
                            <strong>Amount:</strong> {cryptoInfo.amount} {cryptoInfo.currency}<br/>
                            <strong>Timeout:</strong> {cryptoInfo.timeout} minutes<br/>
                            <strong>Verifications needed:</strong> {cryptoInfo.verifications}<br/>
                            {/* Display verifications received */}
                            {cryptoInfo.count > 0 && (
                                <>
                                    <strong>Verifications received:</strong> {cryptoInfo.count}<br/>
                                </>
                            )}
                            <p>A payment can take anywhere from a minute to hours to be confirmed depending on the network congestion.</p>
                            {cryptoInfo.note && <p className="text-red-500">{cryptoInfo.note}</p>}
                        </div>

                        {/* Countdown Timer Display */}
                        <div className="mt-4" id="statusCheck">
                            <p>Next status check in: <strong>{countdown}</strong> seconds.</p>
                        </div>
                    </div>
                )}
            </div>

            {/* QR Code */}
            {cryptoInfo && (
                <div className="mt-6 flex justify-center">
                    <QRCodeCanvas value={cryptoInfo.qr || ''} size={256}/>
                </div>
            )}

            {/* Pay & Submit Button - Moved to the bottom */}
            <div className="mt-6">
                <button
                    disabled={buttonDisabled} // Disable button until enough confirmations
                    className={`px-4 py-2 w-full text-white font-semibold rounded-lg ${buttonDisabled ? 'bg-gray-400' : 'bg-green-500'} transition-colors duration-300`}
                >
                    Pay & Submit
                </button>
            </div>
        </div>
    );
};

export default PaymentMethod;
