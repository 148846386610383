import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { apiRequest } from '../utils/Api'; // Assuming apiRequest is in the utils folder

const ShoppingCart = () => {
    const [cartItems, setCartItems] = useState([]);
    const [cartTotal, setCartTotal] = useState(0);
    const navigate = useNavigate();

    // Function to load cart items from the API
    const loadCartItems = async () => {
        try {
            const data = await apiRequest('ShoppingCart/getItems', 'POST');
            setCartItems(data);
            if (data.length > 0) {
                calculateTotal(data);
            }
        } catch (error) {
            console.error('Error loading cart items:', error);
        }
    };

    // Function to calculate the total price of items in the cart
    const calculateTotal = (items) => {
        const total = items.reduce((sum, item) => sum + item.price * item.quantity, 0);
        setCartTotal(total);
    };

    // Function to handle quantity change
    const handleQuantityChange = async (item, newQuantity) => {
        // Ensure the new quantity does not exceed the available amount
        const validQuantity = newQuantity > item.available ? item.available : newQuantity;

        try {
            const updatedItems = await apiRequest('ShoppingCart/changeQuantity', 'POST', {
                itemId: item.itemId,
                itemSource: item.itemSource,
                quantity: validQuantity,
            });
            setCartItems(updatedItems);
            calculateTotal(updatedItems);
        } catch (error) {
            console.error('Error updating quantity:', error);
        }
    };

    // Function to handle removing an item from the cart
    const handleRemoveItem = async (item) => {
        try {
            const updatedItems = await apiRequest('ShoppingCart/removeItem', 'POST', {
                itemId: item.itemId,
                itemSource: item.itemSource
            });
            setCartItems(updatedItems);
            calculateTotal(updatedItems);
        } catch (error) {
            console.error('Error removing item:', error);
        }
    };

    useEffect(() => {
        // Load cart items on component mount
        loadCartItems();
    }, []);

    return (
        <div className="container mx-auto p-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="md:col-span-2">
                    <h1 className="text-3xl font-bold mb-6">Shopping Cart</h1>

                    {cartItems.length === 0 ? (
                        <div className="text-center">
                            <p className="text-lg">The cart is feeling lonely. Add something from the Marketplace!</p>
                            <Link
                                to="/marketplace"
                                className="mt-4 inline-block bg-customOrange text-white px-4 py-2 rounded-md hover:bg-customDarkBrown"
                            >
                                Go to Marketplace
                            </Link>
                        </div>
                    ) : (
                        <>
                            <table className="table-auto w-full text-left">
                                <thead>
                                <tr>
                                    <th>Pic</th>
                                    <th>Item</th>
                                    <th>Price</th>
                                    <th>Quantity</th>
                                    <th>Total</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {cartItems.map((item) => (
                                    <tr key={item.itemId}>
                                        <td>
                                            <img
                                                src={item.image.replace('img/', 'images/')}
                                                alt={item.name}
                                                className="w-12 h-12 object-cover"
                                            />
                                        </td>
                                        <td>
                                            <a href={`/info/${item.itemSource}/${item.itemId}`}>{item.name}</a>
                                        </td>
                                        <td>{item.price}</td>
                                        <td>
                                            <input
                                                type="number"
                                                value={item.quantity}
                                                onChange={(e) => {
                                                    const newQuantity = parseInt(e.target.value, 10);

                                                    // Validate that quantity does not exceed available stock
                                                    if (newQuantity > item.available) {
                                                        handleQuantityChange(item, item.available);
                                                    } else if (newQuantity < 1) {
                                                        handleQuantityChange(item, 1);  // Minimum quantity is 1
                                                    } else {
                                                        handleQuantityChange(item, newQuantity);
                                                    }
                                                }}
                                                className="w-16 text-center border rounded-md"
                                                min="1"
                                                max={item.available} // Set the maximum to the available quantity
                                            />
                                        </td>
                                        <td>{(item.price * item.quantity).toFixed(2)}</td>
                                        <td>
                                            <button
                                                onClick={() => handleRemoveItem(item)}
                                                className="text-red-600 hover:text-red-800"
                                            >
                                                <i className="fas fa-trash-alt"></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>

                            {/* Total Section */}
                            <div className="mt-4">
                                <h3 className="text-2xl font-semibold">Total: €{cartTotal.toFixed(2)}</h3>
                            </div>

                            {/* Checkout Button */}
                            <div className="mt-6">
                                <button
                                    onClick={() => navigate('/checkout')}
                                    className="bg-customOrange text-customGray px-6 py-3 rounded-md hover:bg-customDarkBrown"
                                >
                                    Checkout
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ShoppingCart;
