import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            "Bank": "Bank",
            "SIE": "SIE",
            "PDF": "PDF",
            "Mejl": "Mail",
            "Övrigt": "Other",
            "Administration": "Administration",
            "Hours": "Hours",
            "Mon": "Mon",
            "Tue": "Tue",
            "Wed": "Wed",
            "Thu": "Thu",
            "Fri": "Fri",
            "Sat": "Sat",
            "Sun": "Sun",
        }
    },
    sv: {
        translation: {
            "Bank": "Bank",
            "SIE": "SIE",
            "PDF": "PDF",
            "Mejl": "Mejl",
            "Övrigt": "Övrigt",
            "Administration": "Administration",
            "Hours": "Timmar",
            "Mon": "Mån",
            "Tue": "Tis",
            "Wed": "Ons",
            "Thu": "Tor",
            "Fri": "Fre",
            "Sat": "Lör",
            "Sun": "Sön",

        }
    }
};

i18n.use(initReactI18next).init({
    resources,
    lng: "sv", // Default language
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
