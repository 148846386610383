import React from 'react';
import {Link} from "react-router-dom";

const NotificationHistory = () => {
    // Placeholder data for notifications
    const notifications = [
        {
            id: 1,
            title: 'KYC Verification Approved',
            date: '2024-12-01',
            description: 'Your KYC verification was successfully approved. You now have full access to all features.',
        },
        {
            id: 2,
            title: 'Payment Received',
            date: '2024-11-28',
            description: 'A payment of €250 has been credited to your account.',
        },
        {
            id: 3,
            title: 'New Promotion Available',
            date: '2024-11-25',
            description: 'Check out our new promotion: 10% cashback on your next purchase.',
        },
        {
            id: 4,
            title: 'Account Password Changed',
            date: '2024-11-20',
            description: 'Your account password was successfully updated.',
        },
    ];

    return (
        <div className="bg-gray-900 text-white min-h-screen px-6 py-8">
            <h1 className="text-3xl font-bold mb-6">Notification History</h1>
            <Link to="/dashboard" className="text-customOrange hover:text-customDarkBrown underline mb-6 block">
                Back to Dashboard
            </Link>

            {/* Notification List */}
            <div className="space-y-6">
                {notifications.map((notification) => (
                    <div key={notification.id} className="bg-gray-800 p-4 rounded-lg shadow-md">
                        <div className="flex justify-between items-center mb-2">
                            <h2 className="text-lg font-semibold">{notification.title}</h2>
                            <p className="text-sm text-gray-400">{notification.date}</p>
                        </div>
                        <p className="text-gray-300">{notification.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default NotificationHistory;
