import React from 'react';
import { Link } from 'react-router-dom';

const Settings = () => {
    return (
        <div className="bg-gray-900 text-white min-h-screen px-6 py-8">
            <h1 className="text-3xl font-bold mb-6">Settings</h1>
            <Link to="/dashboard" className="text-customOrange hover:text-customDarkBrown underline mb-6 block">
                Back to Dashboard
            </Link>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6">
                {/* Profile Settings */}
                <div className="bg-gray-800 p-6 rounded-lg flex flex-col items-start">
                    <h2 className="text-lg font-semibold mb-2">Profile</h2>
                    <p className="text-gray-400 text-sm mb-4">
                        View and edit your personal information.
                    </p>
                    <Link
                        to="/profile"
                        className="bg-orange-500 w-full text-center hover:bg-orange-600 text-white px-4 py-2 rounded-md text-sm font-semibold"
                    >
                        Go to Profile
                    </Link>
                </div>

                {/* Account Settings */}
                <div className="bg-gray-800 p-6 rounded-lg flex flex-col items-start">
                    <h2 className="text-lg font-semibold mb-2">Account Settings</h2>
                    <p className="text-gray-400 text-sm mb-4">
                        Manage your account preferences and security.
                    </p>
                    <Link
                        to="/account-settings"
                        className="bg-orange-500 w-full text-center hover:bg-orange-600 text-white px-4 py-2 rounded-md text-sm font-semibold"
                    >
                        Go to Account Settings
                    </Link>
                </div>

                {/* KYC Verification */}
                <div className="bg-gray-800 p-6 rounded-lg flex flex-col items-start">
                    <h2 className="text-lg font-semibold mb-2">KYC</h2>
                    <p className="text-gray-400 text-sm mb-4">
                        Complete or review your KYC verification status.
                    </p>
                    <Link
                        to="/kyc"
                        className="bg-orange-500 w-full text-center hover:bg-orange-600 text-white px-4 py-2 rounded-md text-sm font-semibold"
                    >
                        Go to KYC
                    </Link>
                </div>

                {/* Add Cards */}
                <div className="bg-gray-800 p-6 rounded-lg flex flex-col items-start">
                    <h2 className="text-lg font-semibold mb-2">Add Cards</h2>
                    <p className="text-gray-400 text-sm mb-4">
                        Add or manage your payment methods.
                    </p>
                    <Link
                        to="/add-cards"
                        className="bg-orange-500 w-full text-center hover:bg-orange-600 text-white px-4 py-2 rounded-md text-sm font-semibold"
                    >
                        Go to Add Cards
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Settings;
