import React, { useEffect, useState } from 'react';
import { apiRequest } from '../../utils/Api'; // Adjust the path to your API utility

const AdminTemplateEditor = () => {
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [formData, setFormData] = useState({ name: '', content: '', email: '' });
    const [emailStatus, setEmailStatus] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [isSendingTest, setIsSendingTest] = useState(false);

    useEffect(() => {
        fetchTemplates();
    }, []);

    const fetchTemplates = async () => {
        try {
            const data = await apiRequest('Admin/listTemplates', 'POST', {});
            setTemplates(data);
        } catch (error) {
            console.error('Error fetching templates:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleCreateTemplate = () => {
        setSelectedTemplate({ name: '', content: '' });
        setFormData({ name: '', content: '', email: '' });
    };

    const loadTemplate = async (name) => {
        try {
            const data = await apiRequest('Admin/getTemplate', 'POST', { name });
            setSelectedTemplate(data);
            setFormData({ name: data.name, content: data.content, email: '' });
        } catch (error) {
            console.error('Error loading template:', error);
        }
    };

    const saveTemplate = async () => {
        if (!/^[a-z_]+$/.test(formData.name)) {
            alert('Invalid template name. Only lowercase letters (a-z) and underscores (_) are allowed.');
            return;
        }

        setIsSaving(true);
        try {
            const data = await apiRequest('Admin/saveTemplate', 'POST', {
                name: formData.name,
                content: formData.content
            });
            alert(data.message);
            fetchTemplates();
        } catch (error) {
            console.error('Error saving template:', error);
        } finally {
            setIsSaving(false);
        }
    };

    const sendTestEmail = async () => {
        if (!formData.email) {
            alert('Please provide an email address for testing.');
            return;
        }

        if (isSaving) {
            alert('Saving the template before sending the test email.');
            await saveTemplate();
        }

        let templateName = formData.name;
        if (formData.content.length < 3) {
            templateName = 'test';
        }

        setIsSendingTest(true);
        try {
            const data = await apiRequest('Admin/sendTestMail', 'POST', { email: formData.email, template: templateName });
            setEmailStatus(data.message);
        } catch (error) {
            console.error('Error sending test email:', error);
        } finally {
            setIsSendingTest(false);
        }
    };

    return (
        <div className="container mx-auto py-4">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {/* Template List */}
                <div className="col-span-1">
                    <button
                        className="bg-blue-500 text-white py-2 px-4 w-full mb-3 rounded hover:bg-blue-600"
                        onClick={handleCreateTemplate}
                    >
                        Create New Template
                    </button>
                    <ul className="list-none">
                        {templates.length > 0 ? (
                            templates.map((template) => (
                                <li
                                    key={template.name}
                                    className="cursor-pointer p-2 bg-gray-100 hover:bg-gray-200 mb-2 rounded"
                                    onClick={() => loadTemplate(template.name)}
                                >
                                    {template.name}
                                </li>
                            ))
                        ) : (
                            <li className="p-2">No templates available</li>
                        )}
                    </ul>
                </div>

                {/* Template Editor */}
                <div className="col-span-2">
                    {selectedTemplate ? (
                        <form id="templateForm">
                            <div className="mb-4">
                                <label htmlFor="name" className="block text-gray-700 mb-2">Template Name</label>
                                <input
                                    type="text"
                                    className="w-full px-3 py-2 border rounded-md"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    disabled={!!selectedTemplate.name}
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="content" className="block text-gray-700 mb-2">Content</label>
                                <textarea
                                    className="w-full px-3 py-2 border rounded-md"
                                    id="content"
                                    name="content"
                                    rows="10"
                                    value={formData.content}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="mb-4">
                                <button
                                    type="button"
                                    className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                                    onClick={saveTemplate}
                                    disabled={isSaving}
                                >
                                    {isSaving ? 'Saving...' : 'Save Template'}
                                </button>
                            </div>

                            <div className="mb-4">
                                <input
                                    type="email"
                                    className="w-full px-3 py-2 border rounded-md"
                                    id="email"
                                    name="email"
                                    placeholder="Enter email for test"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                <button
                                    type="button"
                                    className="bg-gray-500 text-white py-2 px-4 mt-3 rounded hover:bg-gray-600"
                                    onClick={sendTestEmail}
                                    disabled={isSendingTest}
                                >
                                    {isSendingTest ? 'Sending...' : 'Send Test Email'}
                                </button>
                                {emailStatus && <div className="mt-2 text-green-500">{emailStatus}</div>}
                            </div>
                        </form>
                    ) : (
                        <p>Select a template or create a new one.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AdminTemplateEditor;
