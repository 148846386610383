import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';
import {Link} from "react-router-dom";

const Budget = () => {
    const leasingChartRef = useRef(null);
    const microloanChartRef = useRef(null);
    const leasingChartInstance = useRef(null);
    const microloanChartInstance = useRef(null);

    // Placeholder data for leasing and microloan graphs
    const leasingData = {
        labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6', 'Week 7', 'Week 8'],
        values: [2.5, 5, 7.5, 10, 12.5, 15, 17.5, 20], // Cumulative weekly leasing payments
    };

    const microloanData = {
        labels: ['Month 1', 'Month 2', 'Month 3', 'Month 4', 'Month 5', 'Month 6'],
        values: [50, 100, 150, 200, 250, 300], // Cumulative monthly loan payments
    };

    // Function to create or update a chart
    const createOrUpdateChart = (chartRef, chartInstanceRef, data, label, color) => {
        if (chartInstanceRef.current) {
            chartInstanceRef.current.data.labels = data.labels;
            chartInstanceRef.current.data.datasets[0].data = data.values;
            chartInstanceRef.current.update();
        } else {
            const ctx = chartRef.current.getContext('2d');
            chartInstanceRef.current = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: data.labels,
                    datasets: [
                        {
                            label: label,
                            data: data.values,
                            borderColor: color,
                            backgroundColor: `${color}33`, // Light transparent background
                            fill: true,
                            tension: 0.3,
                        },
                    ],
                },
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            display: true,
                            position: 'top',
                        },
                    },
                    scales: {
                        x: {
                            title: {
                                display: true,
                                text: 'Time Period',
                            },
                        },
                        y: {
                            title: {
                                display: true,
                                text: 'Amount (€)',
                            },
                            beginAtZero: true,
                        },
                    },
                },
            });
        }
    };

    // Initialize or update charts on component mount/update
    useEffect(() => {
        const timeout = setTimeout(() => {
            if (leasingChartRef.current) {
                createOrUpdateChart(
                    leasingChartRef,
                    leasingChartInstance,
                    leasingData,
                    'Leasing Payments (€)',
                    'rgba(75, 192, 192, 1)'
                );
            }

            if (microloanChartRef.current) {
                createOrUpdateChart(
                    microloanChartRef,
                    microloanChartInstance,
                    microloanData,
                    'Microloan Payments (€)',
                    'rgba(255, 99, 132, 1)'
                );
            }
        }, 100); // 100ms delay

        return () => {
            clearTimeout(timeout);
            if (leasingChartInstance.current) leasingChartInstance.current.destroy();
            if (microloanChartInstance.current) microloanChartInstance.current.destroy();
        };
    }, []);


    return (
        <div className="container mx-auto mt-6">
            <h1 className="text-3xl font-bold mb-6 text-orange-500">My Budget</h1>
            <Link to="/dashboard" className="text-customOrange hover:text-customDarkBrown underline mb-6 block">
                Back to Dashboard
            </Link>

            {/* Leasing Chart Section */}
            <div className="bg-gray-800 p-6 rounded-lg shadow-lg mb-6">
                <h2 className="text-xl font-semibold text-orange-500 mb-4">Leasing Payments</h2>
                <canvas ref={leasingChartRef} className="w-full h-auto"></canvas>
            </div>

            {/* Microloan Chart Section */}
            <div className="bg-gray-800 p-6 rounded-lg shadow-lg mb-6">
                <h2 className="text-xl font-semibold text-orange-500 mb-4">Microloan Payments</h2>
                <canvas ref={microloanChartRef} className="w-full h-auto"></canvas>
            </div>
        </div>
    );
};

export default Budget;
