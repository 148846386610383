import React from "react";
import { Link } from "react-router-dom";

const FinanceAnalysis = () => {
    return (
        <div className="bg-gray-900 text-white min-h-screen px-6 py-8">
            <h1 className="text-3xl font-bold mb-4">Finance Analysis</h1>
            <Link to="/dashboard" className="text-customOrange hover:text-customDarkBrown underline mb-6 block">
                Back to Dashboard
            </Link>

            {/* Short-Term Suggestions */}
            <div className="bg-gray-800 p-6 rounded-lg mb-6">
                <h2 className="text-xl font-semibold mb-4">Short-Term (Within 5 Years)</h2>
                <p className="text-gray-300 mb-4">
                    These steps are aimed at reducing financial stress and building stability for your personal well-being:
                </p>
                <ul className="list-disc list-inside text-gray-400 space-y-2">
                    <li>Create a budget to track your spending and identify areas to save money.</li>
                    <li>Establish an emergency fund to cover at least 3 to 6 months of living expenses.</li>
                    <li>Pay down high-interest debts to reduce financial burden.</li>
                    <li>Set achievable savings goals for personal development or hobbies you enjoy.</li>
                </ul>
            </div>

            {/* Mid-Term Suggestions */}
            <div className="bg-gray-800 p-6 rounded-lg mb-6">
                <h2 className="text-xl font-semibold mb-4">Mid-Term (Within 20 Years)</h2>
                <p className="text-gray-300 mb-4">
                    These steps focus on growing financial confidence and reducing stress over time:
                </p>
                <ul className="list-disc list-inside text-gray-400 space-y-2">
                    <li>Build a healthy savings habit by automating contributions to your personal savings.</li>
                    <li>Invest in your health and well-being by budgeting for regular checkups, fitness, or therapy.</li>
                    <li>Explore safe, diversified investments to grow your wealth gradually.</li>
                    <li>Plan for life changes, such as upgrading your living situation or pursuing education.</li>
                </ul>
            </div>

            {/* Long-Term Suggestions */}
            <div className="bg-gray-800 p-6 rounded-lg">
                <h2 className="text-xl font-semibold mb-4">Long-Term (Within 40 Years)</h2>
                <p className="text-gray-300 mb-4">
                    These steps aim to secure your long-term financial independence and provide peace of mind:
                </p>
                <ul className="list-disc list-inside text-gray-400 space-y-2">
                    <li>Build financial resilience by maintaining a steady growth-oriented savings plan.</li>
                    <li>Invest in low-risk, long-term assets to ensure stability as you approach retirement.</li>
                    <li>Budget for lifelong hobbies, travel, or interests that bring you joy and fulfillment.</li>
                    <li>Plan for future medical or unexpected expenses to avoid stress during later stages of life.</li>
                </ul>
            </div>

            <div className="mt-6">
                <button
                    className="bg-orange-500 text-white px-6 py-3 rounded-lg hover:bg-orange-600 font-semibold"
                    onClick={() => alert("AI analysis coming soon!")}
                >
                    Run AI Analysis
                </button>
            </div>
        </div>
    );
};

export default FinanceAnalysis;
