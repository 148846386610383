import React, { useEffect, useState } from 'react';
import { apiRequest } from '../../utils/Api'; // Assuming apiRequest is your API handler

const AdminOrderOverview = () => {
    const [orders, setOrders] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const limit = 6;

    // Fetch orders from the API with pagination
    const fetchOrders = async () => {
        setIsLoading(true);
        try {
            const offset = (page - 1) * limit;
            const data = await apiRequest('Admin/orderHistory', 'POST', { limit, offset });
            setOrders(data);
            setError(null);
        } catch (err) {
            setError('Failed to load orders.');
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    // Calculate total sales for the displayed orders
    const calculateTotalSales = () => {
        return orders.reduce((total, order) => total + order.quantity * parseFloat(order.price), 0).toFixed(2);
    };

    // Fetch orders when component mounts or page changes
    useEffect(() => {
        fetchOrders();
    }, [page]);

    // Pagination handlers
    const nextPage = () => setPage((prev) => prev + 1);
    const prevPage = () => setPage((prev) => Math.max(prev - 1, 1));

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold mb-6">Order Overview</h1>

            {/* Display error if any */}
            {error && <p className="text-red-500">{error}</p>}

            {/* Order Table */}
            {isLoading ? (
                <p>Loading orders...</p>
            ) : orders.length > 0 ? (
                <>
                    <table className="table-auto w-full border-collapse border mb-4">
                        <thead>
                        <tr className="bg-gray-100">
                            <th className="border p-2">Transaction ID</th>
                            <th className="border p-2">Item</th>
                            <th className="border p-2">Quantity</th>
                            <th className="border p-2">Price (€)</th>
                            <th className="border p-2">Total (€)</th>
                            <th className="border p-2">Purchase Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {orders.map((order) => (
                            <tr key={order.transactionId}>
                                <td className="border p-2">{order.transactionId}</td>
                                <td className="border p-2">{order.title || 'N/A'}</td>
                                <td className="border p-2">{order.quantity}</td>
                                <td className="border p-2">€{order.price}</td>
                                <td className="border p-2">€{(order.price * order.quantity).toFixed(2)}</td>
                                <td className="border p-2">{new Date(order.purchasedAt).toLocaleDateString()}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                    {/* Total Sales */}
                    <div className="flex justify-end mb-8">
                        <span className="text-lg font-semibold">Total Sales for Displayed Orders: €{calculateTotalSales()}</span>
                    </div>
                </>
            ) : (
                <p>No orders found.</p>
            )}

            {/* Pagination Controls */}
            <div className="flex justify-between mt-4">
                <button
                    onClick={prevPage}
                    disabled={page === 1}
                    className="bg-blue-500 text-white px-4 py-2 rounded disabled:opacity-50"
                >
                    Previous
                </button>
                <button
                    onClick={nextPage}
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default AdminOrderOverview;
