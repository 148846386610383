import React from 'react';
import { SITE_NAME } from "../config";

const Services = () => {
    return (
        <div className="container mx-auto py-12">
            <div className="text-center">
                <h1 className="text-4xl font-bold text-gray-100 mb-6">Our Vision</h1>
                <p className="text-lg text-gray-200 max-w-2xl mx-auto mb-12">
                    Leader in delivering financial empowerment, fostering digital inclusion, and reshaping the future of banking.
                </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {/* Executive Summary */}
                <div className="bg-white shadow-md rounded-lg p-6 hover:shadow-xl transition-shadow">
                    <h3 className="text-xl font-semibold text-gray-600 mb-4 text-center">
                        Executive Summary
                    </h3>
                    <p className="text-gray-600 text-center">
                        {SITE_NAME} is a groundbreaking hybrid bank blending personalized, AI-driven solutions with traditional banking values. It introduces innovative services tailored to meet the diverse needs of individuals and businesses worldwide, and a unique phone licensing program to increase digital accessibility in developing regions.
                    </p>
                </div>

                {/* The Bank of the Future */}
                <div className="bg-white shadow-md rounded-lg p-6 hover:shadow-xl transition-shadow">
                    <h3 className="text-xl font-semibold text-gray-600 mb-4 text-center">
                        The Bank of the Future
                    </h3>
                    <p className="text-gray-600 text-center">
                        Combining digital innovation with proven banking tradition, {SITE_NAME} offers real-time transactions, unmatched account security, and personal financial solutions that grow with you. Whether you prefer the convenience of neobanking or the reliability of traditional banking, we have you covered.
                    </p>
                </div>

                {/* Leasing Program */}
                <div className="bg-white shadow-md rounded-lg p-6 hover:shadow-xl transition-shadow">
                    <h3 className="text-xl font-semibold text-gray-600 mb-4 text-center">
                        Leasing Program
                    </h3>
                    <p className="text-gray-600 text-center">
                        {SITE_NAME} lowers financial barriers in low-income regions by offering smartphones for lease at affordable rates. Each user contributes to an ecosystem that drives engagement and generates value, fostering financial inclusion and sustainable growth.
                    </p>
                </div>
            </div>

            <div className="mt-12 text-center">
                <h2 className="text-3xl font-semibold text-gray-100 mb-6">
                    Why Choose {SITE_NAME}?
                </h2>
                <p className="text-lg text-gray-200 max-w-3xl mx-auto">
                    {SITE_NAME} offers cutting-edge banking technology to empower individuals worldwide. With secure transactions, tailored financial solutions, and a commitment to financial inclusion, {SITE_NAME} is your partner in reshaping the future of banking.
                </p>
            </div>

            <div className="mt-12 grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="bg-gray-50 p-6 rounded-lg shadow-md">
                    <h3 className="text-2xl font-semibold text-gray-800 mb-4">Affordability</h3>
                    <p className="text-gray-600">
                        Our leasing program reduces the financial burden for users in low-income regions, making smartphones accessible and fostering digital inclusion.
                    </p>
                </div>
                <div className="bg-gray-50 p-6 rounded-lg shadow-md">
                    <h3 className="text-2xl font-semibold text-gray-800 mb-4">Security & Reliability</h3>
                    <p className="text-gray-600">
                        Your transactions and accounts are protected with the latest security standards, ensuring your peace of mind while banking with {SITE_NAME}.
                    </p>
                </div>
            </div>

            <div className="mt-12 text-center">
                <a
                    href="/register"
                    className="bg-customOrange text-white py-3 px-6 rounded-lg hover:bg-customDarkBrown transition-colors"
                >
                    Join the Future of Banking Today
                </a>
            </div>
        </div>
    );
};

export default Services;
