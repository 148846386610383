import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiRequest } from '../utils/Api';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setError(null);
        setIsLoading(true);

        try {
            const response = await apiRequest('/auth/login', 'POST', {
                email,
                password
            });

            if (response.error) {
                setError(response.error);
            } else {
                localStorage.setItem('apiInfo', response.apiInfo);
                localStorage.setItem('token', response.token);
                localStorage.setItem('user', JSON.stringify(response.user));
                navigate('/dashboard');
            }
        } catch (err) {
            setError('Login failed.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="container mx-auto mt-10 px-4">
            <div className="max-w-md mx-auto bg-gray-800 text-gray-100 p-8 shadow-lg rounded-lg">
                <h1 className="text-3xl font-bold mb-6 text-center">Login</h1>
                <form onSubmit={handleLogin} className="space-y-6">
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-300">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-gray-600 rounded-md shadow-sm bg-gray-700 text-gray-200 focus:ring-customOrange focus:border-customOrange sm:text-sm"
                            disabled={isLoading}
                        />
                    </div>
                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-300">Password</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-gray-600 rounded-md shadow-sm bg-gray-700 text-gray-200 focus:ring-customOrange focus:border-customOrange sm:text-sm"
                            disabled={isLoading}
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full py-2 px-4 bg-customOrange text-gray-800 rounded-md hover:bg-customDarkBrown border border-customDarkBrown"
                        disabled={isLoading}
                    >
                        {isLoading ? 'Logging in...' : 'Login'}
                    </button>
                </form>

                {error && <div className="mt-4 text-red-500 text-sm">{error}</div>}
            </div>
        </div>
    );
};

export default Login;
