import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Chart from 'chart.js/auto';
import { apiRequest } from '../utils/Api'; // Assuming you already have the `apiRequest` function in utils

const ProductOverview = () => {
    const { itemSource, itemId } = useParams(); // Get itemSource and itemId from the route
    const chartRef = useRef(null);
    const [itemData, setItemData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [shares, setShares] = useState(1); // Default to 1 share
    const [fee, setFee] = useState(0); // Fee from API or default to 0 if not provided
    const chartInstance = useRef(null); // Keep track of the chart instance

    const navigate = useNavigate(); // For handling redirects

    // Fetch item data
    useEffect(() => {
        const fetchItemData = async () => {
            try {
                if (!itemSource || !itemId) {
                    console.error("Invalid itemSource or itemId");
                    return;
                }

                const response = await apiRequest('Marketplace/getItem', 'POST', {
                    itemId,
                    itemSource,
                });

                if (response && response.id) {
                    const apiFee = response.fee || 0;
                    setFee(apiFee); // Set fee from API (if available)
                    setItemData(response); // Update itemData with response
                } else {
                    console.error("Invalid response from API");
                }

                setLoading(false);
            } catch (error) {
                console.error('Error fetching item data:', error);
                setLoading(false);
            }
        };

        fetchItemData();
    }, [itemSource, itemId]);

    // Function to calculate purchase value growth
    const calculateGrowth = () => {
        if (!itemData) return { years: [], values: [] };

        const initialValue = parseFloat(itemData.price) * shares; // Total value based on shares
        const annualYield = parseFloat(itemData.yield) || 0; // Yield as a decimal (assume 0 if not provided)
        const years = [0, 5, 10, 15, 20, 25]; // Now includes year 0

        const values = years.map(year => {
            if (year === 0) return initialValue.toFixed(2); // Year 0 is the initial value

            const growth = initialValue * Math.pow(1 + annualYield, year); // Growth without fee
            const feeDeduction = fee * year * shares; // Deduct the total fee over the years
            return (growth - feeDeduction).toFixed(2); // Deduct fee from growth and return value
        });

        return { years, values };
    };

    // Function to update the chart dynamically
    const updateChart = () => {
        const { years, values } = calculateGrowth();

        if (chartInstance.current) {
            chartInstance.current.data.labels = years;
            chartInstance.current.data.datasets[0].data = values;
            chartInstance.current.update();
        }
    };

    useEffect(() => {
        if (itemData && chartRef.current) {
            const { years, values } = calculateGrowth();

            const ctx = chartRef.current.getContext('2d');

            chartInstance.current = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: years,
                    datasets: [{
                        label: 'Purchase Value Growth (€)',
                        data: values,
                        borderColor: 'rgba(75, 192, 192, 1)',
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                        fill: true,
                        tension: 0.3
                    }]
                },
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            display: true,
                            position: 'top'
                        },
                    },
                    scales: {
                        x: {
                            title: {
                                display: true,
                                text: 'Year'
                            }
                        },
                        y: {
                            title: {
                                display: true,
                                text: 'Value (€)'
                            },
                            beginAtZero: false
                        }
                    }
                }
            });

            return () => chartInstance.current.destroy(); // Clean up the chart on unmount
        }
    }, [itemData]);

    // Update chart whenever shares or fee changes
    useEffect(() => {
        updateChart();
    }, [shares]);

    // Handle Add to Cart
    const addToCart = async (id, quantity) => {
        try {
            const response = await apiRequest('ShoppingCart/addItem', 'POST', {
                itemId: id,
                quantity: parseInt(quantity),
            });
            console.log(response);
            // Optionally handle cart refresh or feedback
        } catch (error) {
            console.error('Error adding to cart:', error);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (!itemData) return <div>No item data available</div>;

    if (itemSource === '1') {
        navigate(`/tutorial/overview/${itemId}`);
        return null;
    }

    return (
        <div className="container mx-auto mt-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {/* Image and Chart */}
                <div className="flex flex-col items-center">
                    <img src={itemData.image} alt={itemData.title} className="w-full h-auto rounded-lg shadow-md" />
                    <canvas ref={chartRef} className="mt-4 w-full h-auto"></canvas>
                </div>

                {/* Item Details */}
                <div className="flex flex-col justify-start">
                    <h3 className="text-2xl font-bold">{itemData.title}</h3>
                    <p className="text-gray-700 mt-4"><strong>Issued by:</strong> {itemData.description.split('\n')[0]}
                    </p>
                    <p className="text-gray-700"><strong>Industry:</strong> {itemData.description.split('\n')[1]}</p>
                    <hr className="my-4"/>
                    <h5 className="text-xl font-semibold">Board Members</h5>
                    <p className="text-gray-700 mt-2"
                       dangerouslySetInnerHTML={{__html: itemData.description.replace(/\n/g, '<br/>').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')}}></p>
                    <hr className="my-4"/>
                    <p className="mt-2 text-lg"><strong>Yield:</strong> {(itemData.yield * 100).toFixed(2)}%</p>
                    <p className="mt-2 text-lg"><strong>Fee:</strong> €{fee.toFixed(2)}</p> {/* Display Fee */}
                    <p className="mt-2 text-lg"><strong>Share Price:</strong> €{parseFloat(itemData.price).toFixed(2)}
                    </p>
                    <p className="mt-2 text-lg"><strong>Available
                        Quantity:</strong> {itemData.quantity.toLocaleString()}</p>

                    {/* Input for Shares */}
                    <div className="mt-6">
                        <label htmlFor="shares" className="block text-sm font-medium text-gray-700">Shares</label>
                        <input
                            type="number"
                            id="shares"
                            min="1"
                            max={itemData.quantity}
                            value={shares}
                            onChange={(e) => {
                                const value = parseInt(e.target.value, 10);

                                // Check if the value exceeds the maximum quantity
                                if (value > itemData.quantity) {
                                    setShares(itemData.quantity);  // Set to maximum available quantity
                                } else if (value < 1) {
                                    setShares(1);  // Set to minimum allowed value
                                } else {
                                    setShares(value);  // Set to entered value if it's valid
                                }
                            }}
                            className="border p-2 rounded-md w-full"
                        />
                    </div>


                    <div className="grid grid-cols-3 gap-4 mt-6">
                        <button
                            className="col-span-3 bg-customOrange hover:bg-customDarkBrown text-white py-2 rounded-md w-full"
                            onClick={() => addToCart(itemData.id, shares)}>
                            <i className="fas fa-cart-plus"></i> Add {shares} to Cart
                        </button>
                    </div>

                    {/* General Information about Bonds */}
                    <div className="mt-10">
                        <h3 className="text-xl font-bold">What are Bonds?</h3>
                        <p className="mt-2 text-gray-700">
                            Bonds are fixed-income instruments that represent a loan made by an investor to a borrower
                            (typically corporate or governmental).
                            A bond could be thought of as an I.O.U. between the lender and borrower that includes the
                            details of the loan and its payments.
                            Bonds are used by companies, municipalities, states, and sovereign governments to finance
                            projects and operations.
                        </p>
                        <h3 className="text-xl font-bold mt-4">How do Bonds Differ from Stocks?</h3>
                        <p className="mt-2 text-gray-700">
                            While stocks represent ownership in a company, bonds represent a loan made to the company
                            (or government).
                            Bondholders are creditors, not owners, and as such, they don’t share in the company’s
                            profits. Bonds are considered a safer investment than stocks,
                            but they also generally offer lower returns.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductOverview;
