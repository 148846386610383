import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { apiRequest } from '../utils/Api'; // Assuming apiRequest is in the utils folder

const ThankYou = () => {
    const { transactionId } = useParams(); // Get the transactionId from the URL
    const [orderDetails, setOrderDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch the order details using transactionId
    useEffect(() => {
        const fetchOrderDetails = async () => {
            try {
                const response = await apiRequest('User/orderByTransactionId', 'POST', {
                    transactionId
                });
                setOrderDetails(response); // Set the fetched order details
                setLoading(false);
            } catch (err) {
                setError('Failed to load order details.');
                setLoading(false);
            }
        };

        if (transactionId) {
            fetchOrderDetails();
        }
    }, [transactionId]);

    if (loading) {
        return <div className="container mx-auto text-center mt-16">Loading order details...</div>;
    }

    if (error) {
        return <div className="container mx-auto text-center mt-16">{error}</div>;
    }

    return (
        <div className="container mx-auto text-center mt-16">
            <h1 className="text-3xl font-bold mb-4">Thank You for Your Order!</h1>
            <p className="text-lg text-gray-700 mb-8">Your payment has been successfully processed.</p>

            {/* Order Overview */}
            {orderDetails ? (
                <>
                    <h2 className="text-2xl font-semibold mb-4">Order Summary</h2>
                    <table className="table-auto w-3/4 mx-auto text-left mb-8 border border-gray-300">
                        <thead>
                        <tr>
                            <th className="border px-4 py-2" colSpan="2">Product</th>
                            <th className="border px-4 py-2">Price</th>
                            <th className="border px-4 py-2">Quantity</th>
                            <th className="border px-4 py-2">Total</th>
                        </tr>
                        </thead>
                        <tbody>
                        {orderDetails.map((item) => (
                            <tr key={item.itemId}>
                                <td className="border px-4 py-2">
                                    <img
                                        src={item.image.replace('/img/', '/images/')}
                                        alt={item.title}
                                        className="w-16 h-16 object-cover"
                                    />
                                </td>
                                <td className="border px-4 py-2">
                                    <a href={`/info/${item.itemSource}/${item.itemId}`} className="text-blue-500 hover:underline">
                                        {item.title}
                                    </a>
                                </td>
                                <td className="border px-4 py-2">€{item.price}</td>
                                <td className="border px-4 py-2">{item.quantity}</td>
                                <td className="border px-4 py-2">€{(item.price * item.quantity).toFixed(2)}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                    <div className="mt-4">
                        <p className="text-lg">
                            <strong>Order Fee:</strong> €{orderDetails.reduce((sum, item) => sum + item.fee, 0).toFixed(2)}
                        </p>
                        <p className="text-lg">
                            <strong>Order Total:</strong> €
                            {orderDetails
                                .reduce((sum, item) => sum + item.price * item.quantity + item.fee, 0)
                                .toFixed(2)}
                        </p>
                    </div>

                    <Link to="/marketplace" className="bg-customOrange hover:bg-customDarkBrown text-white px-6 py-3 rounded-md mt-8 inline-block">
                        Continue Shopping
                    </Link>
                </>
            ) : (
                <p>No order details available.</p>
            )}
        </div>
    );
};

export default ThankYou;
