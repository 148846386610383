import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URL, API_TOKEN } from '../config'; // Assuming you have a config file for constants

const Register = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: ''
    });
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle form submission for step 1 (signup)
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null); // Reset any previous error

        try {
            const response = await fetch(`${API_URL}/Auth/registerBasic`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-TOKEN': API_TOKEN,
                },
                body: JSON.stringify({
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    email: formData.email,
                    password: formData.password
                })
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Registration failed. Please try again.');
            }

            // If registration is successful, set success state and navigate to login or dashboard
            setSuccess(true);
            navigate('/dashboard'); // Redirect to the dashboard after successful registration
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div className="container mx-auto mt-10 px-4">
            <div className="max-w-md mx-auto bg-gray-800 text-gray-100 p-8 shadow-lg rounded-lg">
                <h1 className="text-3xl font-bold mb-6 text-center">Sign-up for Free</h1>

                {/* Show error if any */}
                {error && <p className="text-red-500 mb-4">{error}</p>}

                {/* Show success message if needed */}
                {success && <p className="text-green-500 mb-4">Registration successful! Redirecting...</p>}

                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="firstName" className="block text-sm font-medium text-gray-300">First Name</label>
                        <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border border-gray-600 rounded-md shadow-sm bg-gray-700 text-gray-200 focus:ring-customOrange focus:border-customOrange sm:text-sm"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="lastName" className="block text-sm font-medium text-gray-300">Last Name</label>
                        <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border border-gray-600 rounded-md shadow-sm bg-gray-700 text-gray-200 focus:ring-customOrange focus:border-customOrange sm:text-sm"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-300">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border border-gray-600 rounded-md shadow-sm bg-gray-700 text-gray-200 focus:ring-customOrange focus:border-customOrange sm:text-sm"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-300">Password</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border border-gray-600 rounded-md shadow-sm bg-gray-700 text-gray-200 focus:ring-customOrange focus:border-customOrange sm:text-sm"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full py-2 px-4 rounded-md shadow bg-customOrange hover:bg-customDarkBrown text-gray-800"
                    >
                        Sign Up
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Register;
