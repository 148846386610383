import React, { useEffect, useState } from 'react';
import { apiRequest } from '../utils/Api'; // Assuming you've already got apiRequest for API calls
import { Link } from 'react-router-dom';

const Marketplace = () => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [kyc, setKyc] = useState(false);
    const [cartCount, setCartCount] = useState(0);

    // Fetch marketplace items from the API
    useEffect(() => {
        const fetchMarketplaceItems = async () => {
            try {
                const data = await apiRequest('Marketplace/list', 'POST');
                if (localStorage.getItem('kycActive') === '0') {
                    setKyc(true);
                }
                setItems(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching marketplace items:', error);
                setLoading(false);
            }
        };

        fetchMarketplaceItems();
    }, []);


    const handleTotalItems = () => {
        fetch(`${process.env.REACT_APP_API_URL}/ShoppingCart/getTotalItems`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setCartCount(data); // Directly set the integer returned by the API
            })
            .catch((error) => console.error('Error getting total items:', error));
    };
    // Add item to cart
    const addToCart = async (id) => {
        try {
            if (kyc) {
                alert('You need to complete KYC before you can purchase items.');
                return;
            }
            await apiRequest('ShoppingCart/addItem', 'POST', {
                itemId: id,
                quantity: 1,
            });
            console.log(`Item ${id} added to cart.`);

        } catch (error) {
            console.error('Error adding to cart:', error);
        }
    };

    if (loading) {
        return <div className="text-center py-10">Loading...</div>;
    }

    return (
        <div className="container mx-auto px-4 py-10">
            <h1 className="text-3xl font-semibold mb-6">Marketplace</h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                {items.map((item) => (
                    <div key={item.id} className="bg-white shadow-md rounded-lg overflow-hidden flex flex-col h-full">
                        <img
                            src={item.image.replace('img/','images/') || 'https://via.placeholder.com/300x200'}
                            alt={item.title}
                            className="w-full h-40 object-cover"
                        />
                        <div className="p-4 flex flex-col justify-between flex-grow">
                            <div className="flex-grow">
                                <h3 className="text-lg font-bold">{item.title}</h3>
                                <p className="text-gray-200" dangerouslySetInnerHTML={{ __html: item.description }}></p>
                            </div>
                            <div className="mt-4">
                                <p className="text-sm text-gray-500">Price: €{item.price}</p>
                                <button
                                    onClick={() => addToCart(item.id)}
                                    className="mt-2 w-full bg-indigo-600 text-white py-2 rounded-md hover:bg-indigo-700"
                                >
                                    <i className="fas fa-cart-plus"></i> Add to Cart
                                </button>
                                <Link
                                    to={`/info/${item.itemSource}/${item.itemId}`}
                                    className="mt-2 w-full bg-green-600 text-white py-2 rounded-md hover:bg-green-700 inline-block text-center"
                                >
                                    <i className="fas fa-info-circle"></i> Information
                                </Link>
                            </div>
                        </div>
                    </div>
                ))}

                {/* Custom item for "Create your own tutorial" */}
                <div className="bg-white shadow-md rounded-lg overflow-hidden flex flex-col h-full">
                    <img
                        src="https://via.placeholder.com/300x200"
                        alt="Create your own tutorial"
                        className="w-full h-40 object-cover"
                    />
                    <div className="p-4 flex flex-col justify-between flex-grow">
                        <div>
                            <h3 className="text-lg font-bold">Create your own tutorial</h3>
                            <p className="text-gray-200">Create your own tutorial and helping your peers.</p>
                        </div>
                        <button className="mt-4 w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700">
                            <i className="fas fa-plus"></i> Create Tutorial
                        </button>
                    </div>
                </div>

                {/* Custom item for "List a bond" */}
                <div className="bg-white shadow-md rounded-lg overflow-hidden flex flex-col h-full">
                    <img
                        src="/images/300x200.png"
                        alt="List a bond with us"
                        className="w-full h-40 object-cover"
                    />
                    <div className="p-4 flex flex-col justify-between flex-grow">
                        <div>
                            <h3 className="text-lg font-bold">List a bond with us</h3>
                            <p className="text-gray-200">List your bond with us and get access to a wider audience</p>
                        </div>
                        <button className="mt-4 w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700">
                            <i className="fas fa-plus"></i> List Bond
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Marketplace;
