import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SITE_NAME } from "../config";

const Dashboard = () => {
    const [showKycNotification, setShowKycNotification] = useState(false);
    const [showKycProcessing, setShowKycProcessing] = useState(false);
    const [searchVisible, setSearchVisible] = useState(false); // State to control search bar visibility
    const [searchQuery, setSearchQuery] = useState(''); // State to hold the search input
    const navigate = useNavigate();

    useEffect(() => {
        const kycStatus = localStorage.getItem('kycActive');
        if (!kycStatus || kycStatus === '0') {
            setShowKycNotification(false);
        }
        const kycProcessing = localStorage.getItem('kycProcessing');
        if (kycProcessing === 'true') {
            setShowKycProcessing(false);
        }
    }, []);

    const toggleSearch = () => setSearchVisible(!searchVisible);

    const addCard = () => { // Function to add a new card
        alert('Adding a new card...'); // Replace with real card addition logic
    }

    const handleSearch = () => {
        alert(`Searching for: ${searchQuery}`); // Replace with real search logic
        setSearchQuery(''); // Clear the input after search
    };

    return (
        <div className="bg-gray-900 text-white min-h-screen px-4 py-6">
            {/* KYC Notifications */}
            {showKycProcessing && (
                <div className="bg-red-500 text-white p-3 rounded-md mb-6">
                    <p className="text-center">
                        Your KYC information is being processed, you will receive an email shortly.
                    </p>
                </div>
            )}
            {showKycNotification && !showKycProcessing && (
                <div className="bg-red-500 text-white p-3 rounded-md mb-6">
                    <p className="text-center">
                        You need to complete your KYC.{' '}
                        <Link to="/kyc" className="underline font-bold">
                            Go to KYC page
                        </Link>
                    </p>
                </div>
            )}

            {/* Welcome Header */}
            <h1 className="text-3xl font-bold mb-6">Welcome to {SITE_NAME}</h1>

            {/* Search Bar Section */}
            {searchVisible && (
                <div className="mb-6 flex items-center space-x-2">
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="flex-grow px-4 py-2 rounded-lg bg-gray-800 text-white focus:ring-2 focus:ring-orange-500"
                    />
                    <button
                        onClick={handleSearch}
                        className="px-4 py-2 bg-orange-500 text-white rounded-lg font-semibold hover:bg-orange-600"
                    >
                        Search
                    </button>
                </div>
            )}

            {/* Dashboard Content */}
            <div>
                {/* Header Section */}
                <div className="flex justify-between items-center mb-6">
                    <div>
                        <h2 className="text-lg font-semibold">Your Balance</h2>
                        <p className="text-4xl font-bold">€7,896</p>
                    </div>
                    <div className="space-x-3">
                        <button
                            onClick={toggleSearch}
                            className="bg-gray-800 p-4 rounded-full hover:bg-gray-700"
                        >
                            <i className="fas fa-search text-gray-400"></i>
                        </button>

                        <button
                            onClick={navigate.bind(null, '/notifications')}
                            className="bg-gray-800 p-4 rounded-full hover:bg-gray-700"
                        >
                            <i className="fas fa-bell text-gray-400"></i>
                        </button>
                        <button
                            onClick={navigate.bind(null, '/settings')}
                            className="bg-gray-800 p-4 rounded-full hover:bg-gray-700"
                        >
                            <i className="fas fa-cog text-gray-400"></i>
                        </button>
                    </div>
                </div>

                {/* Other Sections */}
                <div className="grid grid-cols-2 gap-4 mb-6">
                    {/* Card Details */}
                    <div
                        className="bg-gradient-to-r from-green-400 to-teal-500 rounded-lg p-4 cursor-pointer"
                        onClick={() => navigate("/payment-history", { state: { cardType: "VISA - Salary" } })}
                    >
                        <h3 className="text-sm font-medium">VISA</h3>
                        <p className="text-lg font-semibold">Salary</p>
                        <p className="text-2xl font-bold">€2,230</p>
                        <p className="text-sm opacity-70 mt-1">**** 8921</p>
                    </div>
                    <div
                        className="bg-gradient-to-r from-yellow-400 to-orange-500 rounded-lg p-4 cursor-pointer"
                        onClick={() => navigate("/payment-history", { state: { cardType: "VISA - Savings Account" } })}
                    >
                        <h3 className="text-sm font-medium">VISA</h3>
                        <p className="text-lg font-semibold">Savings Account</p>
                        <p className="text-2xl font-bold">€5,566</p>
                        <p className="text-sm opacity-70 mt-1">**** 4952</p>
                    </div>
                </div>

                {/* Finance Tools */}
                <div className="grid grid-cols-3 gap-4 mb-6">
                    <div
                        className="flex flex-col items-center bg-gray-800 p-4 rounded-lg cursor-pointer"
                        onClick={() => navigate('/expense-summary')}
                    >
                        <i className="fas fa-receipt text-xl text-yellow-500 mb-2"></i>
                        <p className="text-sm text-center">Expense Summary</p>
                    </div>
                    <div
                        className="flex flex-col items-center bg-gray-800 p-4 rounded-lg cursor-pointer"
                        onClick={() => navigate('/budget')}
                    >
                        <i className="fas fa-chart-line text-xl text-green-500 mb-2"></i>
                        <p className="text-sm text-center">My Budget</p>
                    </div>
                    <div
                        className="flex flex-col items-center bg-gray-800 p-4 rounded-lg cursor-pointer"
                        onClick={() => navigate('/finance-analysis')}
                    >
                        <i className="fas fa-file-invoice-dollar text-xl text-blue-500 mb-2"></i>
                        <p className="text-sm text-center">Finance Analysis</p>
                    </div>
                </div>

                {/* Loans Section */}
                <div className="mb-6">
                    <h3 className="text-lg font-semibold mb-3">Current Loans</h3>
                    <div className="bg-gray-800 p-4 rounded-lg cursor-pointer"
                         onClick={() => navigate('/loans')}
                    >
                        <p className="text-sm">Account NP 3874825</p>
                        <p className="text-lg font-bold">€78.92</p>
                        <p className="text-sm text-gray-400">Expires: 10/12/2024</p>
                    </div>
                </div>

                {/* Phone Leasing Section */}
                <div className="mb-6">
                    <h3 className="text-lg font-semibold mb-3">Phone Leasing</h3>
                    <div
                        className="bg-gray-800 p-4 rounded-lg flex items-center cursor-pointer"
                        onClick={() => navigate("/leasing-overview")}
                    >
                        <div className="flex-grow">
                            <p className="text-sm">Leased Smartphone</p>
                            <p className="text-lg font-bold">Paid 3 out of 32 weeks</p>
                            <p className="text-sm text-gray-400">Next Payment: 2024-12-16</p>
                        </div>
                        <img
                            src="/images/smart128.webp"
                            alt="Leased Phone"
                            className="w-16 h-16 rounded-lg object-cover"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
