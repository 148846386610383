import React, { useState } from 'react';

const PasswordReset = () => {
    const [email, setEmail] = useState('');
    const [isSent, setIsSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');

        const formData = { email };

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/Auth/forgotPassword`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();
            if (data.success) {
                setIsSent(true);
                localStorage.removeItem('token');
            } else {
                setErrorMessage('There was an error processing your request. Please try again.');
            }
        } catch (error) {
            setErrorMessage('An unexpected error occurred. Please try again.');
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full">
                {!isSent ? (
                    <>
                        <h2 className="text-2xl font-bold text-center mb-4">Reset Your Password</h2>
                        <p className="text-center mb-6">
                            Enter your email address below, and we'll send you a link to reset your password.
                        </p>

                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Email address
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                />
                            </div>

                            {errorMessage && (
                                <div className="mb-4 text-red-500 text-center">
                                    {errorMessage}
                                </div>
                            )}

                            <button
                                type="submit"
                                className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700"
                            >
                                Send Reset Link
                            </button>
                        </form>
                    </>
                ) : (
                    <div className="text-center">
                        <h2 className="text-2xl font-bold mb-4">Check Your Email</h2>
                        <p className="mb-6">
                            A reset link has been sent to your email address. Please check your inbox and follow the instructions to reset your password.
                        </p>
                        <p className="text-sm text-gray-500">
                            If you use Facebook or Google login, please reset your password via the respective service as we do not store your password.
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PasswordReset;
