import React, { useState, useEffect } from 'react';
import { apiRequest } from '../../utils/Api'; // Adjust the path to your API utility

const AdminKYC = () => {
    const [kycList, setKycList] = useState([]);
    const [selectedKyc, setSelectedKyc] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isLoadingKycDetails, setIsLoadingKycDetails] = useState(false);

    useEffect(() => {
        // Fetch KYC list
        const fetchKycList = async () => {
            try {
                const data = await apiRequest('User/getKycList', 'POST');
                if (data.length === 0) {
                    setKycList([]);
                } else {
                    setKycList(data);
                }
            } catch (error) {
                setError('Failed to load KYC requests.');
            } finally {
                setLoading(false);
            }
        };

        fetchKycList();
    }, []);

    const handleKycSelect = async (id) => {
        setIsLoadingKycDetails(true);
        setSelectedKyc(null); // Reset selected KYC before fetching new one
        try {
            const data = await apiRequest('Admin/getKyc', 'POST', { id });
            setSelectedKyc(data);
        } catch (error) {
            setError('Failed to load KYC details.');
        } finally {
            setIsLoadingKycDetails(false);
        }
    };

    const handleStatusChange = async (id, status, note = '') => {
        const params = { note };
        try {
            await apiRequest('Admin/updateStatus', 'POST', { id, status, param: params });
            // Reload KYC list after status update
            window.location.reload();
        } catch (error) {
            alert('Failed to update KYC status');
        }
    };

    if (loading) return <div className="text-center py-4">Loading...</div>;
    if (error) return <div className="text-center py-4 text-red-500">{error}</div>;

    return (
        <div className="container mx-auto py-4">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {/* Left column: KYC List */}
                <div className="col-span-1">
                    <h3 className="text-xl font-bold mb-4">KYC Requests</h3>
                    {kycList.length === 0 ? (
                        <p>No KYC requests</p>
                    ) : (
                        <ul className="list-group">
                            {kycList.map((kyc) => (
                                <li
                                    key={kyc.id}
                                    className="list-group-item cursor-pointer hover:bg-gray-200 p-2 rounded transition-colors duration-200"
                                    onClick={() => handleKycSelect(kyc.id)}
                                >
                                    {kyc.firstName} {kyc.middleName} {kyc.surName}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>

                {/* Right column: KYC Details */}
                <div className="col-span-2">
                    {isLoadingKycDetails ? (
                        <div className="text-center">
                            <i className="fas fa-spinner fa-spin text-4xl"></i>
                            <p>Loading KYC details...</p>
                        </div>
                    ) : selectedKyc ? (
                        <div>
                            <h3 className="text-xl font-bold mb-4">KYC Details</h3>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <p><strong>Email:</strong> {selectedKyc.email}</p>
                                    <p><strong>First Name:</strong> {selectedKyc.firstName}</p>
                                    <p><strong>Middle Name:</strong> {selectedKyc.middleName}</p>
                                    <p><strong>Last Name:</strong> {selectedKyc.surName}</p>
                                    <p><strong>Country:</strong> {selectedKyc.country}</p>
                                    <p><strong>City:</strong> {selectedKyc.city}</p>
                                    <p><strong>Address:</strong> {selectedKyc.address}</p>
                                    <p><strong>Postal Code:</strong> {selectedKyc.postcode}</p>
                                    <p><strong>Date of Birth:</strong> {selectedKyc.dob}</p>
                                    <p><strong>National ID:</strong> {selectedKyc.nationalIdNumber}</p>
                                    <p><strong>PEP:</strong> {selectedKyc.isPep ? 'Yes' : 'No'}</p>
                                    <p><strong>Phone:</strong> {selectedKyc.phonenumber}</p>
                                    <p><strong>Source of Funds:</strong> {selectedKyc.fundSource}</p>
                                </div>
                                <div>
                                    <p><strong>Proof of Address:</strong></p>
                                    <img src={selectedKyc.proofAddressId.thumb} alt="Proof of Address" className="w-full mb-4" />
                                    <p><strong>ID Card (Front):</strong></p>
                                    <img src={selectedKyc.proofIdcard1Id.thumb} alt="Proof of ID Card Front" className="w-full mb-4" />
                                    <p><strong>ID Card (Back):</strong></p>
                                    <img src={selectedKyc.proofIdcard2Id.thumb} alt="Proof of ID Card Back" className="w-full mb-4" />
                                </div>
                            </div>

                            <div className="mt-6">
                                <button
                                    onClick={() => {
                                        const note = prompt('Enter reason for approval', 'Approved by admin');
                                        if (note) handleStatusChange(selectedKyc.id, 1, note);
                                    }}
                                    className="mr-2 bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
                                >
                                    Approve
                                </button>
                                <button
                                    onClick={() => {
                                        const note = prompt('Enter reason for rejection');
                                        if (note) handleStatusChange(selectedKyc.id, 2, note);
                                    }}
                                    className="mr-2 bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700"
                                >
                                    Reject
                                </button>
                                <button
                                    onClick={() => handleStatusChange(selectedKyc.id, 0)}
                                    className="mr-2 bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                                >
                                    Request New
                                </button>
                                <button
                                    onClick={() => {
                                        const note = prompt('Enter reason for partial KYC request');
                                        if (note) handleStatusChange(selectedKyc.id, 3, note);
                                    }}
                                    className="bg-yellow-600 text-white py-2 px-4 rounded hover:bg-yellow-700"
                                >
                                    Request Partial
                                </button>
                            </div>
                        </div>
                    ) : (
                        <p>Select a KYC request to view details</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AdminKYC;
