import React from "react";

const Support = () => {
    const handleFormSubmit = (e) => {
        e.preventDefault();
        alert("Thank you for reaching out. We'll get back to you soon!");
    };

    return (
        <div className="bg-gray-900 text-white min-h-screen p-6">
            <h1 className="text-3xl font-bold mb-6 text-orange-500">Support</h1>

            {/* Contact Form Section */}
            <div className="bg-gray-800 p-6 rounded-lg shadow-lg mb-6">
                <h2 className="text-xl font-semibold text-orange-500 mb-4">Contact Us</h2>
                <form onSubmit={handleFormSubmit}>
                    <div className="mb-4">
                        <label htmlFor="name" className="block text-sm font-medium">Name</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            required
                            className="w-full px-3 py-2 mt-1 rounded-lg bg-gray-700 text-white"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-sm font-medium">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            required
                            className="w-full px-3 py-2 mt-1 rounded-lg bg-gray-700 text-white"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="message" className="block text-sm font-medium">Message</label>
                        <textarea
                            id="message"
                            name="message"
                            rows="4"
                            required
                            className="w-full px-3 py-2 mt-1 rounded-lg bg-gray-700 text-white"
                        ></textarea>
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-orange-500 hover:bg-orange-600 text-white py-2 rounded-lg font-semibold"
                    >
                        Send Message
                    </button>
                </form>
            </div>
            {/* Chat Section */}
            <div className="bg-gray-800 p-6 rounded-lg shadow-lg mb-6">
                <h2 className="text-xl font-semibold text-orange-500 mb-4">Chat with Us</h2>
                <p className="text-gray-300 mb-4">
                    Our support team is here to assist you. Please click the button below to start a live chat.
                </p>
                <button
                    className="w-full bg-green-500 hover:bg-green-600 text-white py-2 rounded-lg font-semibold"
                    onClick={() => alert("Chat feature coming soon!")}
                >
                    Start Chat
                </button>
            </div>

            {/* Address Information Section */}
            <div className="bg-gray-800 p-6 rounded-lg shadow-lg mb-6">
                <h2 className="text-xl font-semibold text-orange-500 mb-4">Our Address</h2>
                <p>
                    <strong>Mstrpayment AB</strong>
                </p>
                <p>Org.nr 559209-6100</p>
                <p>Lutabäcksvägen 3 c</p>
                <p>703 75 Örebro</p>
                <p>Sweden</p>
            </div>

            {/* Request a copy of my data */}
            <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                <h2 className="text-xl font-semibold text-orange-500 mb-4">Request a Copy of Your Data</h2>
                <p className="text-gray-300 mb-4">
                    You can request a copy of your data that we store on our servers. Click the button below to request your data.<br/>
                    A copy of your data will be sent to your email address, please allow up to 24 hours for processing.
                </p>
                <button
                    className="w-full bg-blue-500 hover:bg-blue-600 text-white py-2 rounded-lg font-semibold"
                    onClick={() => alert("Data request feature coming soon!")}
                >
                    Request Data
                </button>
            </div>
        </div>
    );
};

export default Support;
