// components/ItemSourceSelect.js
import React, { useState, useEffect } from 'react';
import { apiRequest } from '../utils/Api';

const ItemSourceSelect = ({ selectedSource, onChange }) => {
    const [itemSources, setItemSources] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchItemSources();
    }, []);

    const fetchItemSources = async () => {
        try {
            const response = await apiRequest('Admin/getItemSources', 'POST');
            if (response) {
                setItemSources(response);
            }
        } catch (error) {
            console.error('Failed to load item sources:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSourceChange = (e) => {
        onChange(e.target.value);
    };

    if (isLoading) {
        return <p>Loading item sources...</p>;
    }

    return (
        <div>
            <label htmlFor="itemSource" className="block text-gray-700 font-semibold">Item Source</label>
            <select
                id="itemSource"
                name="itemSource"
                value={selectedSource}
                onChange={handleSourceChange}
                className="w-full border px-3 py-2 rounded-md"
            >
                <option value="">Select Item Source</option>
                {Object.entries(itemSources).map(([value, label]) => (
                    <option key={value} value={value}>
                        {label}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default ItemSourceSelect;
